/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { notification, Form, Input, Button, Upload, Select } from 'antd'
import './style/surveyForm.scss'
import CheckBox from '../../../components/Checkbox'
import Doubt from '../../../assets/images/users.svg'
import FileIcon from '../../../assets/images/FileIcon.png'
import { addEmployee, bulkEmployee, getMyProjectsLists } from '../../../api/API'
import Plus from '../../../assets/images/plus.svg'
import Bulk from '../../../assets/images/bulk.svg'

const { Dragger } = Upload
const { Option } = Select

interface dProps {
  company: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getCompanyDetail: any
}
export const BulkUpload: React.FC<dProps> = ({
  company,
  setDrawerVisible,
  getCompanyDetail,
}): JSX.Element => {
  const [data, setData] = useState(company)
  // const [viewType, setviewType] = useState
  const [file, setFile] = useState<any>()
  // const [chk, setchk] = useState(false)
  const [projectId, setProjectId] = useState('')
  const [projectList, setProjectList] = useState<any>()
  const [rerenderState, setRerenderState] = useState(Math.random())

  useEffect(() => {
    getMyProjectsLists(company._id).then((res: any) => {
      setProjectList(res.data.data)
    })
  }, [])

  const props = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    beforeUpload: async (files: any) => {
      setFile(files)
      // Prevent upload
      return false
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        notification.success({
          message: `${info.file.name} file uploaded successfully`,
        })
      } else if (status === 'error') {
        notification.error({
          message: `${info.file.name} file upload failed.`,
        })
      }
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const addFile = (e: any): void => {
    setFile(e[0])
  }
  const handleBulkSubmit = (): void => {
    // eslint-disable-next-line no-underscore-dangle
    const companyId = data._id
    const formData = new FormData()
    formData.append('companyId', companyId)
    formData.append('projectId', projectId)
    formData.append('file', file)
    bulkEmployee(formData)
      .then((res: any) => {
        if (res.data.status === 'fail') {
          notification.error({
            message: res.data.message,
          })
        } else {
          setDrawerVisible(false)
          getCompanyDetail()
          notification.open({
            type: 'success',
            message: 'Employee successfully Added',
          })
          window.location.reload()
        }
      })
      .catch((e: any) => {
        notification.error({
          type: 'error',
          message: 'something goes worong',
        })
      })
  }
  const handleOnDrop = (e: any): void => {
    // e.preventDefault()
    // addFile(e.dataTransfer.files)
    console.log(e)
  }

  return (
    <div className="bulk-upload-wrapper">
      <div className="bulk-upload1">
        <Dragger {...props} className="file-upload-container">
          <img src={FileIcon} alt="fileIcon" className="file-upload-img" />
          <span className="file-upload-msg">
            Drag CSV file to upload the data,
          </span>
          <div className="or-wrapper">
            <hr className="or-line" />
            <span className="or-span">OR</span>
            <hr className="or-line" />
          </div>
          <Button className="browse-btn" style={{ zIndex: 0 }}>
            <span className="btn-text">Browse</span>
          </Button>
        </Dragger>
        <div className="form-row">
          <div className="form-group col col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Project
              <span className="asterik">*</span>
            </label>
            <Form.Item
              name="state"
              rules={[{ required: true, message: 'Please Select Project' }]}
            >
              <Select
                onChange={(e: any) => setProjectId(e.target.value)}
                allowClear
                placeholder="Select Project"
              >
                {projectList &&
                  projectList.map((project: any) => (
                    <Option value={project._id} key={project._id}>
                      {project.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="bulk-reminder-wrapper">
          <div className="survey-note-wrapper">
            <span className="survey-note-title">Please Note</span>
            <span className="survey-note-desc">
              User will receive an invite email to join to the particular
              Company
            </span>
          </div>
          <div className="survey-note-wrapper">
            <span className="survey-note-title">
              Check the sample CSV file:{' '}
            </span>
            <span className="survey-note-desc">
              Please create the fields of your csv file same as{' '}
              <a href="/assets/Sample/employee.csv" download>
                sample
              </a>{' '}
              file to bulk upload the data
            </span>
          </div>
        </div>
      </div>
      <div className="button-group">
        <Button
          className="cancelButton"
          onClick={() => setDrawerVisible(false)}
        >
          Cancel
        </Button>
        <Button className="saveButton" onClick={() => handleBulkSubmit()}>
          Save
        </Button>
      </div>
    </div>
  )
}

interface Props {
  company: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getCompanyDetail: any
}
const SingleEmp: React.FC<Props> = ({
  company,
  setDrawerVisible,
  getCompanyDetail,
}): JSX.Element => {
  // const location = useLocation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [projectId, setProjectId] = useState([])
  const [chk, setchk] = useState(false)
  const [projectList, setProjectList] = useState<any>()
  const [form] = Form.useForm()

  useEffect(() => {
    getMyProjectsLists(company._id).then((res: any) => {
      setProjectList(res.data.data)
    })
  }, [])

  const onReset = (): void => {
    form.resetFields()
  }

  const handleSubmit = (): void => {
    console.log(projectId)
    // const ttt = []
    // const a = Object.keys(projectId)
    // for (let i = 0; i < a.length; i+1) {
    //   ttt.push(a[i])
    // }
    // console.log(ttt)
    const companyId = company._id
    const data = {
      name,
      email,
      phone,
      // chk,
      companyId,
      projectId,
      fromData: 'Company',
    }
    console.log(typeof projectId)

    // console.log(a)
    // console.log(typeof a)
    addEmployee(data)
      .then((res: any) => {
        if (res.data.status === 'fail') {
          notification.error({
            message: res.data.message,
          })
        } else {
          setName('')
          setEmail('')
          setPhone('')
          // setchk(false)
          notification.open({
            message: 'Employee successfully Added',
          })
          onReset()
          setDrawerVisible(false)
          getCompanyDetail()
        }
      })
      .catch((e: any) => {
        notification.error({
          message: 'something goes wrong',
        })
      })
  }
  return (
    <Form className="form-wrapper" onFinish={handleSubmit}>
      <div className="survey-inner-form">
        <div className="form-group">
          <label htmlFor="inputName4" className="form-label">
            Name
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please enter full name' }]}
          >
            <Input
              onChange={(e: any) => setName(e.target.value)}
              placeholder="Please enter full name"
            />
          </Form.Item>
        </div>
        <div className="form-group">
          <label htmlFor="inputEmail4" className="form-label">
            Email
            <span className="asterik">*</span>
          </label>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The email is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter your E-mail!',
              },
            ]}
          >
            <Input
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Please input your E-mail!"
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <div className="form-group col col-md-12">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Project
              <span className="asterik">*</span>
            </label>
            <Form.Item
              name="state"
              rules={[{ required: true, message: 'Please Select Project' }]}
            >
              <Select
                onChange={(e: any) => setProjectId(e)}
                allowClear
                mode="multiple"
                placeholder="Select Project"
              >
                {projectList &&
                  projectList.map((project: any) => (
                    <Option value={project._id} key={project._id}>
                      {project.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col col-md-6">
            <label htmlFor="inputPhoneNumber" className="form-label">
              Phone
              {/* <span className="asterik">*</span> */}
            </label>
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: false,
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Enter Valid phone number',
                },
              ]}
            >
              <Input
                onChange={(e: any) => setPhone(e.target.value)}
                placeholder="Enter phone number"
              />
            </Form.Item>
          </div>
        </div>
        <div className="survey-note-wrapper">
          <span className="survey-note-title">Please Note</span>
          <span className="survey-note-desc">
            User will receive an invite email to join to the particular Company
          </span>
        </div>
      </div>
      <div className="button-group">
        <Button
          className="cancelButton"
          onClick={() => {
            onReset()
            setDrawerVisible(false)
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="saveButton">
          Save
        </Button>
      </div>
    </Form>
  )
}

interface sProps {
  company: any
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  getCompanyDetail: any
}
export const EmployeeForm: React.FC<sProps> = ({
  company,
  setDrawerVisible,
  getCompanyDetail,
}): JSX.Element => {
  const [radio, setRadio] = useState(1)
  const [data, setData] = useState(company)
  const [singleTab, setSingleTab] = useState(true)
  const [bulkTab, setBulkTab] = useState(false)
  useEffect(() => {
    setData(company)
  })
  return (
    <div className="survey-form">
      <div className="upper-form">
        <div className="img-container mt-4 mb-5">
          <img src={company.logo || Bulk} alt="dd" className="upper-img" />
          <img src={Plus} alt="plus" className="upper-img-plus ms-3 me-3" />
          <img src={Bulk} alt="bulk" className="upper-img-bulk" />
        </div>
        <div className="survey-radio-container">
          <Button
            className="survey-radio-item"
            id={singleTab ? 'active-tab-border' : 'deactive-tab-border'}
            onClick={() => {
              setRadio(1)
              setSingleTab(true)
              setBulkTab(false)
            }}
          >
            <span className="survey-radio-title">Single Employee</span>
          </Button>
          <Button
            className="survey-radio-item"
            id={bulkTab ? 'active-tab-border' : 'deactive-tab-border'}
            onClick={() => {
              setRadio(2)
              setSingleTab(false)
              setBulkTab(true)
            }}
          >
            <span className="survey-radio-title">Bulk Upload</span>
          </Button>
        </div>
        <hr className="survey-stats-line" />
      </div>
      <div className="vh-100">
        {radio === 2 && (
          <BulkUpload
            company={data}
            setDrawerVisible={setDrawerVisible}
            getCompanyDetail={getCompanyDetail}
          />
        )}
        {radio === 1 && (
          <SingleEmp
            company={data}
            setDrawerVisible={setDrawerVisible}
            getCompanyDetail={getCompanyDetail}
          />
        )}
      </div>
    </div>
  )
}
