/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Alert,
  Form,
  Input,
  Select,
  Drawer,
  notification,
  Button,
} from 'antd'
import CameraIcon from '../../../assets/images/Icon feather-camera.svg'
import '../../company/components/tabs/components/styles/userDetails.scss'
import UserIcon from '../../../assets/images/Icon awesome-user-circle.svg'
import Edit from '../../../assets/images/edit.svg'
import '../style/employeeProfile.scss'
import { currentEmployeeDetail } from '../../../api/API'
import { EditEmployeeProfile } from './EditEmployeeProfile'

// interface Props {
//   data: any
//   isReadOnly: boolean
//   setDrawerVisible: any
//   setIsReadOnly: any
// }
export const EmployeeProfile: React.FC = (): JSX.Element => {
  const children = []
  const [employeeData, setEmployeeData] = useState<any>({})
  const [employeeId, setEmployeeId] = useState()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  // eslint-disable-next-line no-plusplus
  for (let i = 10; i < 36; i++) {
    children.push(
      // eslint-disable-next-line react/jsx-no-undef
      `<Option key=${i.toString(36) + i}>${i.toString(36) + i}</Option>`,
    )
  }

  useEffect(() => {
    currentEmployeeDetail().then((res: any) => {
      // console.log(res.data.data)
      setEmployeeData(res.data.data)
      setEmployeeId(res.data.data._id)
    })
  }, [])

  return (
    <>
      <Form className="pt-0 employee-profile">
        <div className="d-flex justify-content-between align-items-center pt-3">
          {/* <p>My Profile</p> */}
          {/* <div className="position-relative"> */}
          <img
            className="profile-pic"
            src={employeeData.image || UserIcon}
            alt="profile-pic"
          />
          <label className="filebutton">
            {/* <img src={CameraIcon} alt="Camrea" className="w-60 mt-1" /> */}
            <span>
              {/* <input
                type="file"
                id="myfile"
                readOnly
                value={logo}
                // onChange={(e) => imagePreview(e)}
              /> */}
            </span>
          </label>
          <Button
            className="float-end mt-3"
            onClick={() => setIsDrawerVisible(true)}
          >
            <img src={Edit} alt="edit" className="w-25 me-2" />
            Edit
          </Button>
          {/* </div> */}
        </div>
        <Row justify="space-between" className="mt-3" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <label>
              User Name{/* <span className="text-danger">*</span> */}
            </label>
            <Form.Item
              rules={[{ required: true, message: 'Please Enter First Name' }]}
            >
              <Input
                placeholder="Clark"
                className="popup-sidebar-form-input"
                value={employeeData.name}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <label>
              First Name {/* <span className="text-danger">*</span> */}
            </label>
            <Form.Item>
              <Input
                placeholder="Clark"
                className="popup-sidebar-form-input"
                value={employeeData.first_name}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <label>
              Last Name{/* <span className="text-danger">*</span> */}
            </label>
            <Form.Item>
              <Input
                placeholder="Clark"
                className="popup-sidebar-form-input"
                value={employeeData.last_name}
                readOnly
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <label>
            Permissions <span className="text-danger">*</span>
          </label>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={['a10', 'c12']}
            // className="popup-sidebar-form-input"
          >
            {children}
          </Select>
        </Col> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <label>Email{/* <span className="text-danger">*</span> */}</label>
            <Form.Item>
              {/* <Input
                className="popup-sidebar-form-input"
                value={employeeData.email}
                readOnly
              /> */}
              <p className="email-readonly-input form-control mb-0">
                {employeeData.email}
              </p>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item>
              Phone {/* <span className="text-danger">*</span> */}
              <Input
                className="popup-sidebar-form-input"
                value={employeeData.phone}
                readOnly
                //   disabled={isReadOnly}
              />
              {/* {employeeData.phone} */}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* Employee Profile Edit Section Drawer */}
      <Drawer
        title="Edit Employee Profile 20"
        visible={isDrawerVisible}
        // onOk={handleAddProject}
        // onCancel={handleCancel}={handleOnSubmit}
        closeIcon={<CloseOutlined onClick={() => setIsDrawerVisible(false)} />}
        // okText="Save"
        width={window.innerWidth > 550 ? 465 : '100%'}
        className="ProjectModal"
      >
        <EditEmployeeProfile
          employeeId={employeeId}
          employeeData={employeeData}
          setIsDrawerVisible={setIsDrawerVisible}
        />
      </Drawer>
    </>
  )
}
