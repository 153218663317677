/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
// import { Alert } from 'antd'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Row, Col, Typography, notification } from 'antd'
import './style/login.scss'
import { LoginApi } from '../../api/API'
import { createCookie, readCookie } from '../../api/CookieScript'
import Form from '../../components/Form'
import FormItem from '../../components/FormItem'
import Checkbox from '../../components/Checkbox'
import Image from '../../components/Image'
import Input from '../../components/InputElements/Input'
import Password from '../../components/InputElements/Password'
import girlPic from '../../assets/images/login-text-img.png'
import emailIcon from '../../assets/images/icon-material-email.svg'
import lockIcon from '../../assets/images/Icon-material-lock.svg'
import logo from '../../assets/images/Group 9409.svg'
import Label from '../../components/Label'
import Link from '../../components/Link'

import {
  CONTACT_US,
  LOGIN,
  PRODUCT_DESCRIPTION,
  FORGOT_PASSWORD,
  REMEMBER_ME,
  PRODUCT_DESCRIPTION_LINE2,
  WELCOME_BACK,
  LOGIN_DESCRIPTION,
  ENTER_VALID_EMAIL,
  ENTER_VALID_PASSWORD,
  WRONG_CRADENTIAL,
} from '../../constants'

// eslint-disable-next-line import/no-mutable-exports
let ErrorMessage
export type errorState = {
  errorMsg: any
}
export const Login: React.FC = (): JSX.Element => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState<any>([])
  const [rememberMe, setIsRememberMe] = useState(false)
  const [emailErr, setemailErr] = useState<any>('')
  const [passwordErr, setpasswordErr] = useState<any>('')

  const { Text, Title } = Typography
  useEffect(() => {
    const token = readCookie('gm360_token')
    if (token) {
      history.push('/dashboard')
    }
  })
  const handleLogin = () => {
    let valid = true
    setemailErr('')
    setpasswordErr('')
    if (email === '' && password === '') {
      setIsError(true)
      setemailErr('Please Enter Email')
      setpasswordErr('Please Enter password')
      valid = false
    }
    if (email === '') {
      setIsError(true)
      setemailErr('Please Enter Email')
      valid = false
    }
    if (password === '') {
      setIsError(true)
      setpasswordErr('Please Enter password')
      valid = false
    }
    if (valid) {
      setpasswordErr('')
      setemailErr('')
      LoginApi(email, password)
        .then((res: any): any => {
          const response = res.data

          console.log(response)
          createCookie(
            'gm360_name',
            `${response.data.first_name} ${response.data.last_name}`,
            1,
          )
          if (rememberMe) {
            createCookie('gm360_token', response.token, 365)
            createCookie('role', response.data.role, 365)
          } else {
            createCookie('gm360_token', response.token, 1)
            createCookie('role', response.data.role, 1)
          }
          axios.defaults.headers.common.Authorization = `Bearer ${readCookie(
            'gm360_token',
          )}`
          history.push('/dashboard')
        })
        .catch((error: any) => {
          console.log('Error in login', error.message)
          if (error.response) {
            if (error.response.status === 401) {
              setpasswordErr('Please Enter Correct password')
              setemailErr('Please Enter Correct Email')
              setIsError(true)
            }
          } else {
            notification.error({
              message: 'Something goes wrong',
              description:
                'try after few minutes and if not work then contact our service provider',
            })
          }
        })
    }
  }

  const setEmailId = (e: any): void => {
    setEmail(e)
  }
  const updatePassword = (e: any): void => {
    setPassword(e)
  }

  const setrememberMe = (e: any): void => {
    setIsRememberMe(e.target.checked)
  }

  return (
    <div className="login-container">
      <div className="col-1">
        <div className="col-1-section">
          <div className="innerSecWrap">
            <div className="col-1-section-1 headLftTop">
              <Image src={girlPic} alt="girl hold books" />
              <p>
                {PRODUCT_DESCRIPTION}
                <br />
                {PRODUCT_DESCRIPTION_LINE2}
              </p>
              <br />
              <Link url="/user-inquiry" target="">
                <button type="button" className="btn-lg btn-custome btn-theme">
                  {CONTACT_US}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="column-2 position-relative">
        <div className="col-2">
          <div className="col-2-section">
            <div className="col-2-section-1">
              <div className="logoWrap">
                <Image src={logo} alt="GrowMetrix" imgClass="logoImg" />
              </div>
            </div>
            <div className="col-2-section-2">
              <Label
                title={WELCOME_BACK}
                labelClass="heading-h4 fw-bold text-black"
              />
              <br />
              <Label title={LOGIN_DESCRIPTION} labelClass="heading-h4" />
            </div>
            {/* <Alert
              message={errorMsg && errorMsg.map((data: any) => <div>{data}</div>)}
              type="error"
              style={{ display: isError ? 'block' : 'none' }}
            /> */}
            <Form>
              <FormItem formItemClass="mb-4">
                <Input
                  placeholder="Email"
                  onChange={setEmailId}
                  prefix={
                    <Image src={emailIcon} alt="Email icon" preview={false} />
                  }
                />
                <Text
                  className="text-danger"
                  style={{ display: isError ? 'block' : 'none' }}
                >
                  {emailErr}
                </Text>
              </FormItem>
              <FormItem formItemClass="mb-4">
                <Password
                  placeholder="Password"
                  onChange={updatePassword}
                  prefix={
                    <Image src={lockIcon} alt="Password icon" preview={false} />
                  }
                />
                <Text
                  className="text-danger"
                  style={{ display: isError ? 'block' : 'none' }}
                >
                  {passwordErr}
                </Text>
              </FormItem>
              <FormItem>
                <Row className="forgot-password">
                  <Col xxl={13} xl={13} lg={14} sm={24} xs={24}>
                    <div id="rememberMe">
                      {/* <Label forValue="rememberCheck" title="Remember me" /> */}
                      <Checkbox onClick={setrememberMe}>
                        <strong>{REMEMBER_ME}</strong>
                      </Checkbox>
                    </div>
                  </Col>
                  <Col
                    xxl={11}
                    xl={11}
                    lg={10}
                    xs={24}
                    className="justify-content-xl-end"
                  >
                    <div id="forget_password" className="text-right fw-bold">
                      {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link url="/reset-password" target="">
                          {FORGOT_PASSWORD}
                        </Link>
                      }
                    </div>
                  </Col>
                </Row>
              </FormItem>
              <FormItem>
                <button
                  type="button"
                  // eslint-disable-next-line no-return-assign
                  onClick={handleLogin}
                  // onClick={() => (window.location.href = '/dashboard')}
                  className="btn-lg w-100 btn-custome"
                >
                  {LOGIN}
                </button>
              </FormItem>
            </Form>
            {/* Footer */}
          </div>
        </div>
        <div id="footer_data">
          <div id="footer_description">
            <Text>Copyright 2021 GrowMetrix PTY LTD.All Rights Reserved.</Text>
          </div>
          <div id="footer_menus">
            <a href="#"> TERMS & CONDITIONS</a>
            <a href="#"> COOKIE POLICY</a>
            <a href="#"> PRIVACY POLICY</a>
          </div>
        </div>
      </div>
    </div>
  )
}
export { ErrorMessage }
