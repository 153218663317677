/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  notification,
  Modal,
  Form,
  Spin,
  Popconfirm,
} from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import {
  BgColorsOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import BlockForm from './components/survey/BlockForm'
import Undo from '../../../assets/images/undo.svg'
import Redo from '../../../assets/images/Redo.svg'
import InfoIcon from '../../../assets/images/tint.svg'
import SettingImg from '../../../assets/images/Group 9363.svg'
import MoreImg from '../../../assets/images/more.svg'
import MultiChoiceIcon from '../../../assets/images/Icon open-list-rich.svg'
import FreeTextIcon from '../../../assets/images/Group 9208.svg'
import CheckboxIcon from '../../../assets/images/Icon material-playlist-add-check.svg'
import DropdownIcon from '../../../assets/images/Icon awesome-spell-check.svg'
import MetrixTableIcon from '../../../assets/images/Icon metro-table.svg'
import { PublishableFrom } from './components/survey/FormPreview'
import {
  addQuestion,
  getQuestions,
  publishForm,
  moveToTrash,
  restoreFromTrash,
  getAllFocusArea,
  getConstructs,
  getAllRaters,
} from '../../../api/API'

const { Option } = Select
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { v4: uuidv4 } = require('uuid')

interface Prop {
  projectDetail: any
}

export const Survey: React.FC<Prop> = ({ projectDetail }) => {
  // const location = useLocation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const params = useParams<any>()
  const [numberOfChoice, setNumberOfChoice] = useState<number>(3)
  const [raterlist, setRaterList] = useState<any>([])
  const [metrixtableScaleArrayLength, setMetrixtableScaleArrayLength] =
    useState<number>(5)
  const [selectedQuestionType, setSelectedQuestionType] = useState('free-text')

  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [metrixtableScaleArray, setMetrixtableScaleArray] = useState([
    1, 2, 3, 4, 5,
  ])

  const [questionActions, setQuestionAction] = useState('')

  const [selectedQuestionKeyFocusArea, setSelectedQuestionKeyFocusArea] =
    useState('')
  const [selectedQuestionConstruct, setSelectedQuestionConstruct] = useState('')
  const [FocusAreaData, setFocusAreaData] = useState<any>([])
  const [construct, setConstruct] = useState<any>([])

  // this one is for creating form, preview form and this will be send to the backend
  const [questionList, setQuestionList] = useState<any[]>([])

  const [selectedBlock, setSelectedBlock] = useState(0)
  const [selectedQuestion, setSelectedQuestion] = useState(0)

  const [lastAdded, setLastAdded] = useState({ block_no: 0, question_no: 0 })

  const [multiStepFormContainer, setMultiStepFormContainer] = useState<any>([])

  const [selectedQuestionRaters, setSelectedQuestionRaters] = useState<
    string[]
  >([])

  // const [tempCounter, setTempCounter] = useState(0)
  const [loader, setLoader] = useState(false)

  const [isSurveyPublish, setIsSurveyPublish] = useState(false)

  const multichoice = [
    {
      value: 1,
      text: 'Significant development needed',
    },
    {
      value: 2,
      text: 'Development needed',
    },
    {
      value: 3,
      text: 'Effective',
    },
    {
      value: 4,
      text: 'Strength',
    },
    {
      value: 5,
      text: 'Significant strength ',
    },
    {
      value: 0,
      text: 'No opportunity to observe',
    },
  ] as any

  const dropdown = [
    {
      value: 1,
      text: 'Click to write DropDown Options 1',
    },
    {
      value: 2,
      text: 'Click to write DropDown Options 2',
    },
    {
      value: 3,
      text: 'Click to write DropDown Options 3',
    },
  ] as any

  const metrixtablestatement = [
    {
      value: 1,
      text: 'Click to write Statement 1',
    },
    {
      value: 2,
      text: 'Click to write Statement 2',
    },
    {
      value: 3,
      text: 'Click to write Statement 3',
    },
  ] as any

  useEffect(() => {
    getQuestionsFromDB()
    getFocusAreas()
    getRaters()
  }, [])

  useEffect(() => {
    setSelectedQuestionConstruct('')
    if (selectedQuestionKeyFocusArea) {
      getConstructs(selectedQuestionKeyFocusArea).then((res: any) => {
        setConstruct([])
        const { data } = res.data
        setConstruct(data)
        if (data.length > 0) {
          setSelectedQuestionConstruct(selectedQuestionConstruct)
        }
      })
    }
  }, [selectedQuestionKeyFocusArea])

  useEffect(() => {
    // console.log(selectedQuestionRaters)
  }, [selectedQuestionRaters])

  const getFocusAreas = (): void => {
    getAllFocusArea(params.id).then((res: any) => {
      const { data } = res.data
      setFocusAreaData(data)
    })
  }

  const getRaters = (): void => {
    getAllRaters(params.id).then((res: any) => {
      const { data } = res.data
      setRaterList(data)
    })
  }

  const getQuestionsFromDB = (): void => {
    setLoader(true)
    getQuestions(params.id).then((res: any) => {
      const { data } = res.data
      setQuestionList([])
      // setBlock(data)
      if (data.questionList.length > 0) {
        setQuestionList(data.questionList)
        setIsSurveyPublish(data.surveyState)
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        addQuestionToList('html-editor', 'initiaL')
      }
      setLoader(false)
    })
  }

  const addQuestionsTodb = (): void => {
    setLoader(true)
    // console.log(selectedQuestion, questionList)
    addQuestion(questionList).then((res: any) => {
      getQuestionsFromDB()
    })
  }

  const changeQuestionType = (e: any): any => {
    let options
    let allowMultiple = false
    if (e === 'multiple-choice') {
      options = multichoice
    }
    if (e === 'dropdown') {
      options = dropdown
    }
    if (e === 'matrix-table') {
      options = metrixtablestatement
    }
    if (e === 'checkbox') {
      options = multichoice
      allowMultiple = true
    }

    const list = [...questionList]
    list[selectedBlock].data[selectedQuestion].questionType = e
    list[selectedBlock].data[selectedQuestion].options = options
    if (e === 'checkbox') {
      list[selectedBlock].data[selectedQuestion].allowMultiple = allowMultiple
    }
    setQuestionList(list)
    setSelectedQuestionType(e)
    // setBlock(values)
    // addQuestionsTodb()
  }
  const incrementChoiceConter = (): void => {
    setNumberOfChoice(numberOfChoice + 1)
    if (
      selectedQuestionType === 'multiple-choice' ||
      selectedQuestionType === 'checkbox'
    ) {
      const values = [...questionList]

      const { options }: any = values[selectedBlock].data[selectedQuestion]

      options.push({
        value: options.length + 1,
        text: `Click to write Choice ${options.length + 1}`,
      })
      values[selectedBlock].data[selectedQuestion].options = options
      setQuestionList(values)
    }

    if (selectedQuestionType === 'dropdown') {
      const values = [...questionList]

      const { options }: any = values[selectedBlock].data[selectedQuestion]

      options.push({
        value: options.length + 1,
        text: `Click to write DropDown Options ${options.length + 1}`,
      })
      values[selectedBlock].data[selectedQuestion].options = options
      setQuestionList(values)
    }

    if (selectedQuestionType === 'matrix-table') {
      const values = [...questionList]
      const { options }: any = values[selectedBlock].data[selectedQuestion]
      options.push({
        value: options.length + 1,
        text: `Click to write Statement 1 ${options.length + 1}`,
      })
      values[selectedBlock].data[selectedQuestion].options = options
      setQuestionList(values)
    }
  }
  const decrementChoiceConter = (): void => {
    // eslint-disable-next-line no-unused-expressions
    if (numberOfChoice > 1) {
      setNumberOfChoice(numberOfChoice - 1)
      const values = [...questionList]
      const { options }: any = values[selectedBlock].data[selectedQuestion]
      options.splice(-1, 1)
      values[selectedBlock].data[selectedQuestion].options = options
      setQuestionList(values)
    } else {
      setNumberOfChoice(1)
    }
  }

  const decrementScalePointConter = (): void => {
    const values = [...questionList]
    const metrixtableScaleArrayt: any =
      values[selectedBlock].data[selectedQuestion].metrixtableScaleArray
    if (metrixtableScaleArrayt.length > 1) {
      metrixtableScaleArrayt.splice(-1, 1)
      // values[selectedBlock].data[selectedQuestion].options = options
      setMetrixtableScaleArrayLength(metrixtableScaleArrayLength - 1)
      setMetrixtableScaleArray(
        values[selectedBlock].data[selectedQuestion].metrixtableScaleArray,
      )
      setQuestionList(values)
    }
  }

  const incrementScalePointConter = (): void => {
    const values = [...questionList]
    values[selectedBlock].data[selectedQuestion].metrixtableScaleArray.push(1)
    // values[selectedBlock].data[selectedQuestion].options = options
    setMetrixtableScaleArrayLength(metrixtableScaleArrayLength + 1)
    setMetrixtableScaleArray(
      values[selectedBlock].data[selectedQuestion].metrixtableScaleArray,
    )
    setQuestionList(values)
  }

  const changeKeyFocusArea = (e: any): void => {
    const values = [...questionList]
    values[selectedBlock].data[selectedQuestion].keyfocusArea = e
    setQuestionList(values)
    // addQuestionsTodb()
    // setSelectedQuestionKeyFocusArea(FocusAreaData[e]._id)
    setSelectedQuestionKeyFocusArea(e)
    setSelectedQuestionConstruct('')
    getConstructs(e).then((res: any) => {
      setConstruct([])
      const { data } = res.data
      setConstruct(data)
      if (data.length > 0) {
        setSelectedQuestionConstruct('')
      }
    })
  }

  const changeConstruct = (e: any): void => {
    if (construct.length > 0) {
      const values = [...questionList]
      values[selectedBlock].data[selectedQuestion].construct = e
      setQuestionList(values)
      setSelectedQuestionConstruct(e)
    } else {
      setSelectedQuestionConstruct('')
    }
    // addQuestionsTodb()
  }

  const moveQuestion = (fromIndex: any, toIndex: any): void => {
    const currentList = [...questionList]
    const element = currentList[selectedBlock].data[fromIndex]
    currentList[selectedBlock].data.splice(fromIndex, 1)
    currentList[selectedBlock].data.splice(toIndex, 0, element)

    for (let i = 0; i < currentList.length; i++) {
      for (let j = 0; j < currentList[i].data.length; j++) {
        currentList[selectedBlock].data[j].questionPosition = j
      }
    }

    setQuestionList(currentList)
  }

  const addQuestionToList = (
    e: string,
    isNew?: string,
    AboveOrBelow?: string,
    questionNo?: any,
  ): void => {
    let options
    let qtype
    let allowMultiple = false
    if (isNew !== 'duplicate') {
      setSelectedQuestionKeyFocusArea('')
      setSelectedQuestionConstruct('')
    }

    if (isNew === 'yes') {
      setSelectedQuestionKeyFocusArea('')
      setSelectedQuestionConstruct('')
    }

    if (e === 'free-text') {
      options = []
      qtype = 'free-text'
    } else if (e === 'multiple-choice') {
      options =
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].options
          : multichoice
      qtype = 'multiple-choice'
    } else if (e === 'dropdown') {
      options =
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].options
          : dropdown
      qtype = 'dropdown'
    } else if (e === 'matrix-table') {
      // const t = [...metrixtablestatement]
      // t.shift()
      options =
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].options
          : metrixtablestatement
      qtype = 'matrix-table'
    } else if (e === 'checkbox') {
      options =
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].options
          : multichoice
      qtype = 'checkbox'
      allowMultiple = true
    } else if (e === 'html-editor') {
      options = []
      qtype = 'html-editor'
      // addQuestionToList('page-break')
    } else {
      qtype = 'page-break'
    }

    let questionPosition: any = isNew

    // if (isNew === 'yes') {

    questionPosition =
      questionList &&
      questionList[selectedBlock] &&
      questionList[selectedBlock].data &&
      questionList[selectedBlock].data
        ? questionList[selectedBlock].data.length
        : 0
    // }

    // if (isNew === 'duplicate') {
    //   questionPosition = selectedQuestion
    // }

    // if (AboveOrBelow === 'below') {
    //   questionPosition = selectedQuestion
    // }

    // if (AboveOrBelow === 'above') {
    //   questionPosition = selectedQuestion - 2
    // }
    // console.log(selectedBlock, selectedQuestion)
    const questions = {
      questionCounter: uuidv4(),
      questionType: qtype,
      questionTitle:
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].questionTitle
          : 'Write your question here',
      htmlData: {},
      keyfocusArea:
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].keyfocusArea
          : selectedQuestionKeyFocusArea,
      construct:
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion].construct
          : selectedQuestionConstruct,
      options,
      metrixtableScaleArray:
        isNew === 'duplicate'
          ? questionList[selectedBlock].data[selectedQuestion]
              .metrixtableScaleArray
          : [1, 2, 3, 4, 5],
      isMandatory: isNew === 'mandatory' ? e : false,
      allowMultiple,
      asktoraters: [],
      questionPosition,
    }

    const newQuestion = {
      id: uuidv4(),
      blockNo: selectedBlock,
      project_id: params.id, // project id
      data: [questions],
    }

    const currentList = [...questionList]
    if (
      currentList.length > 0 &&
      currentList[selectedBlock] &&
      isNew !== 'yes'
    ) {
      if (isNew === 'duplicate') {
        currentList[selectedBlock].data.splice(questionNo + 1, 0, questions)
      } else {
        currentList[selectedBlock].data.push(questions)
      }
    } else if (AboveOrBelow === 'above') {
      currentList[selectedBlock].data.splice(questionNo, 0, questions)
    } else if (AboveOrBelow === 'below') {
      currentList[selectedBlock].data.splice(questionNo + 1, 0, questions)
    } else {
      currentList.push(newQuestion)
    }

    setQuestionList(currentList)
    if (isNew !== 'initiaL') {
      // addQuestionsTodb()
      setLastAdded({
        block_no: selectedBlock,
        question_no: questionList[selectedBlock]
          ? questionList[selectedBlock].data.length
          : 0,
      })
    }

    const currentList2 = [...questionList]
    for (let i = 0; i < currentList2.length; i++) {
      for (let j = 0; j < currentList2[i].data.length; j++) {
        currentList2[selectedBlock].data[j].questionPosition = j
      }
    }
    setQuestionList(currentList2)

    setQuestionAction('add')

    if (e === 'html-editor') {
      // addQuestionToList('page-break')
    }
  }

  const updateQuestionList = (
    e: any,
    forWhat: string,
    currentLablePosition?: any,
    optionLabel?: any,
  ): void => {
    // const currentList = [...questionList] as any
    const currentList = questionList

    // set question title
    if (forWhat === 'question') {
      currentList[selectedBlock].data[selectedQuestion].questionTitle = e
    }

    // set html-editor
    if (forWhat === 'html-editor') {
      currentList[selectedBlock].data[selectedQuestion].htmlData = e
    }

    // set question Mandatory
    if (forWhat === 'mandatory') {
      if (e) {
        currentList[selectedBlock].data[selectedQuestion].isMandatory = true
      } else {
        currentList[selectedBlock].data[selectedQuestion].isMandatory = false
      }
    }

    // set scale points
    if (forWhat === 'scale-point-value') {
      const options = [
        ...currentList[selectedBlock].data[selectedQuestion]
          .metrixtableScaleArray,
      ] as any

      if (options[currentLablePosition]) {
        options[currentLablePosition] = e
      } else {
        options.push(e)
      }

      currentList[selectedBlock].data[selectedQuestion].metrixtableScaleArray =
        options
    }

    // set scale options
    if (
      forWhat === 'multichioce-options' ||
      forWhat === 'checkbox-options' ||
      forWhat === 'scale-point-statement' ||
      forWhat === 'dropdown-options'
    ) {
      // const options = [
      //   ...currentList[selectedBlock].data[selectedQuestion].options,
      // ] as any
      const { options } = currentList[selectedBlock].data[selectedQuestion]
      if (options[currentLablePosition]) {
        if (optionLabel !== 'yes') {
          options[currentLablePosition].text = e
        }
        if (optionLabel === 'yes') {
          options[currentLablePosition].value = e
        }
      } else {
        const optionForm = {
          value: optionLabel === 'yes' ? e : currentLablePosition,
          text: e,
        }
        options.push(optionForm)
      }
      currentList[selectedBlock].data[selectedQuestion].options = options
    }

    setQuestionList(currentList)
    // addQuestionsTodb()
  }

  const fnpublishForm = (): void => {
    // console.log(selectedBlock)
    // console.log(selectedQuestion)
    // console.log(multiStepFormContainer)
    publishForm(params.id).then((res: any) => {
      notification.success({
        message: res.data.message,
        description: '',
      })
    })
  }

  const handleMoveToTrash = (data: any): void => {
    moveToTrash(data).then((res: any) => {
      notification.success({
        message: res.data.message,
        description: '',
      })
    })
  }
  const changeAnswertype = (e: string): void => {
    // const values = [...block]
    const list = [...questionList]
    if (e === 'allow-one') {
      // values[selectedBlock].questionList[selectedQuestion].allowMultiple = false
      if (list.length > 0) {
        list[selectedBlock].data[selectedQuestion].allowMultiple = false
      }
    } else if (e === 'allow-multiple') {
      // values[selectedBlock].questionList[selectedQuestion].allowMultiple = true
      if (list.length > 0) {
        list[selectedBlock].data[selectedQuestion].allowMultiple = true
      }
    }
    if (list.length > 0) {
      setQuestionList(list)
    }
    // setBlock(values)
    // addQuestionsTodb()
  }

  const generateArrayForMultiStepFrom = (): any => {
    const combineArray: any[] = []
    let sliceContainer: any[] = []
    const tp = multiStepFormContainer
    tp.length = 0
    setMultiStepFormContainer(tp)
    // console.log(questionList)
    // console.log(multiStepFormContainer)
    // eslint-disable-next-line array-callback-return
    questionList.map((b, i) => {
      b.data.map((question: any, index: number) => {
        return combineArray.push(question)
      })
      if (i !== questionList.length - 1) {
        combineArray.push({
          questionCounter: uuidv4(),
          questionType: 'page-break',
          options: [],
          keyfocusArea: null,
          construct: null,
          allowMultiple: false,
        })
      }
    })

    // eslint-disable-next-line array-callback-return
    for (let i = 0; i < combineArray.length; i++) {
      if (
        combineArray[i].questionType === 'page-break' ||
        combineArray[i].questionType === 'html-editor'
      ) {
        const tmp = multiStepFormContainer
        if (combineArray[i].questionType === 'html-editor') {
          sliceContainer.push(combineArray[i])
        }

        tmp.push(sliceContainer)
        setMultiStepFormContainer(tmp)
        sliceContainer = []
      } else {
        sliceContainer.push(combineArray[i])
        if (i === combineArray.length - 1) {
          const tmp = multiStepFormContainer
          tmp.push(sliceContainer)
          sliceContainer = []
          setMultiStepFormContainer(tmp)
        } else if (combineArray[i + 1].questionType === 'html-editor') {
          const tmp = multiStepFormContainer
          tmp.push(sliceContainer)
          sliceContainer = []
          setMultiStepFormContainer(tmp)
        }
      }
    }
    setIsOpenDrawer(true)
  }

  const dragOverOnBlock = (e: any): void => {
    e.preventDefault()
  }

  const handleUndo = (): void => {
    if (questionActions === 'removed') {
      restoreFromTrash(params.id).then((res: any) => {
        const result = res.data
        if (result.data) {
          getQuestionsFromDB()
          setQuestionAction('add')
        } else {
          notification.warning({
            message: result.message,
            description: '',
          })
        }
      })
    }

    if (questionActions === 'add') {
      const question =
        questionList[lastAdded.block_no].data[lastAdded.question_no - 1]
      const qdb = {
        title: question.questionTitle,
        project_id: params.id,
        block_no: selectedBlock,
        options: question.options,
        question_type: question.questionType,
        require: question.isMandatory,
        allow_multiple: question.allowMultiple,
        key_focus_area: question.keyfocusArea,
        construct: question.construct,
        metrixtableScaleArray,
      }
      handleMoveToTrash(qdb)
      const list = [...questionList]
      if (list.length > 0) {
        list[lastAdded.block_no].data.splice(lastAdded.question_no - 1, 1)
        setQuestionList(list)
      }
      // addQuestionsTodb()
      setQuestionAction('removed')
    }
  }

  const handleRedo = (): void => {
    // if (questionActions === 'removed') {
    restoreFromTrash(params.id).then((res: any) => {
      const result = res.data
      if (result.data) {
        getQuestionsFromDB()
        setQuestionAction('add')
      } else {
        notification.warning({
          message: result.message,
          description: '',
        })
      }
    })
    // }
  }

  const changeRaters = (e: any): void => {
    const values = [...questionList]
    // setTempCounter(tempCounter + 1)
    values[selectedBlock].data[selectedQuestion].asktoraters = e
    setQuestionList(values)
    // console.log(values)
    // addQuestionsTodb()
  }

  const showModal = (): any => {
    setIsModalVisible(true)
  }

  const handleCancel = (): any => {
    setIsModalVisible(false)
  }

  return (
    <div className="servey-container">
      <Row className="display-type surveyLeftBar">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={5}
          // className="survey-desktop-header"
        >
          <div className="border">
            <div className="p-3">
              <b>Survey configuration</b>
            </div>
            <hr style={{ width: '98%', margin: '0 auto' }} />
            <Row gutter={[16, 16]} className="mt-3 p-3" justify="space-between">
              <Col sm={24} lg={12} xl={24} xxl={24}>
                <span className="font-smaller text-dark">Question Type</span>
                <Select
                  value={selectedQuestionType}
                  style={{ width: '100%', height: '42px' }}
                  onSelect={(e: any) => changeQuestionType(e)}
                  className="select_config"
                >
                  <Option value="html-editor">
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="p-1 pb-0 pt-0">
                        <BgColorsOutlined
                          className="pe-1"
                          style={{ fontSize: '26px' }}
                        />
                      </div>
                      <div className="ms-2">
                        <b>Html Editor</b>
                        <br />
                        <span className="text-small text-muted">
                          Add description
                        </span>
                      </div>
                    </div>
                  </Option>
                  <Option value="multiple-choice">
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="p-1 pb-0 pt-0">
                        <img src={MultiChoiceIcon} alt="Multiple Choice" />
                      </div>
                      <div className="ms-2">
                        <b>Multiple Choice</b>
                        <br />
                        <span className="text-small text-muted">
                          Choose from a set of Question
                        </span>
                      </div>
                    </div>
                  </Option>
                  <Option value="free-text">
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="pb-0 pt-0">
                        <img src={FreeTextIcon} alt="FreeTextIcon" />
                      </div>
                      <div className="ms-2 text-truncate">
                        <b>Free Text</b>
                        <br />
                        <span className="text-small text-muted">
                          Choose from a set of Question
                        </span>
                      </div>
                    </div>
                  </Option>
                  <Option value="checkbox">
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="p-1 pb-0 pt-0">
                        <img src={CheckboxIcon} alt="CheckboxIcon" />
                      </div>
                      <div className="ms-2">
                        <b>Checkboxes</b>
                        <br />
                        <span className="text-small text-muted">
                          Choose from a set of Question
                        </span>
                      </div>
                    </div>
                  </Option>
                  <Option value="dropdown">
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="p-1 pb-0 pt-0">
                        <img src={DropdownIcon} alt="DropdownIcon" />
                      </div>
                      <div className="ms-2">
                        <b>Dropdown</b>
                        <br />
                        <span className="text-small text-muted">
                          Choose from a set of Question
                        </span>
                      </div>
                    </div>
                  </Option>
                  <Option value="matrix-table">
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="p-1 pb-0 pt-0">
                        <img src={MetrixTableIcon} alt="MetrixTableIcon" />
                      </div>
                      <div className="ms-2">
                        <b>Matrix Table</b>
                        <br />
                        <span className="text-small text-muted">
                          Choose from a set of Question
                        </span>
                      </div>
                    </div>
                  </Option>
                </Select>
              </Col>
              <Col
                sm={24}
                lg={12}
                xl={24}
                xxl={24}
                style={{
                  display:
                    selectedQuestionType === 'matrix-table' ||
                    selectedQuestionType === 'multiple-choice' ||
                    selectedQuestionType === 'checkbox'
                      ? 'block'
                      : 'none',
                }}
              >
                <span className="font-smaller text-dark">Answer type</span>
                <Select
                  defaultValue="Select Answer type"
                  style={{ width: '100%' }}
                  onSelect={(e: any) => changeAnswertype(e)}
                >
                  <Option value="allow-one">Allow one answer</Option>
                  <Option value="allow-multiple">Allow Multiple</Option>
                </Select>
              </Col>
              <Col
                sm={24}
                lg={12}
                xl={24}
                xxl={24}
                className="choices-counter"
                style={{
                  display:
                    selectedQuestionType === 'matrix-table' ||
                    selectedQuestionType === 'multiple-choice' ||
                    selectedQuestionType === 'checkbox'
                      ? 'block'
                      : 'none',
                }}
              >
                <span className="font-smaller text-dark">
                  Number of choices
                </span>
                <Button
                  className="ms-2"
                  onClick={() => decrementChoiceConter()}
                >
                  <MinusOutlined />
                </Button>
                <Input
                  placeholder="03"
                  value={numberOfChoice}
                  className="w-25"
                  disabled
                />
                <Button onClick={() => incrementChoiceConter()}>
                  <PlusOutlined />
                </Button>
              </Col>
              <Col
                sm={24}
                lg={12}
                xl={24}
                xxl={24}
                className="scale-counter"
                style={{
                  display:
                    selectedQuestionType === 'matrix-table' ? 'block' : 'none',
                }}
              >
                <span className="font-smaller text-dark">Scale Point</span>
                <Button
                  className="ms-2"
                  onClick={() => decrementScalePointConter()}
                >
                  <MinusOutlined />
                </Button>
                <Input
                  placeholder="03"
                  value={metrixtableScaleArrayLength}
                  className="w-25"
                  disabled
                />
                <Button onClick={() => incrementScalePointConter()}>
                  <PlusOutlined />
                </Button>
              </Col>
              <Col span={24} sm={24} lg={12} xl={24} xxl={24}>
                <span className="font-smaller text-dark">Raters to ask</span>
                <Select
                  style={{ width: '100%' }}
                  key={selectedQuestionRaters.join()}
                  // key="asktoraters"
                  defaultValue={selectedQuestionRaters}
                  mode="multiple"
                  allowClear
                  onChange={changeRaters}
                >
                  {raterlist.length > 0 &&
                    raterlist.map((category: any, index: number) => (
                      <Option key={category._id} value={category._id}>
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              height: '10px',
                              width: '10px',
                              marginLeft: '10px',
                              backgroundColor: category.color,
                            }}
                          />
                          <div className="ms-2">{category.title}</div>
                        </div>
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={24} sm={24} lg={12} xl={24} xxl={24}>
                <span className="font-smaller text-muted">Key focus area</span>
                <Select
                  key={selectedQuestionKeyFocusArea}
                  defaultValue={selectedQuestionKeyFocusArea}
                  style={{ width: '100%' }}
                  onSelect={changeKeyFocusArea}
                  className="focusareadropdown"
                >
                  {FocusAreaData.length > 0 &&
                    FocusAreaData.map((focus: any, index: any) => (
                      <Option value={focus._id} key={focus._id}>
                        <div
                          className="d-flex align-items-center mt-1 mb-2"
                          style={{ lineHeight: '15px' }}
                        >
                          <div className="pr-1">
                            {/* <img src={focus.image} alt="Icon" /> */}
                            <div
                              className="p-1 pb-0 pt-1"
                              style={{
                                backgroundColor: `${focus.color}`,
                                height: '30px',
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                          <div className="ms-2">
                            <b>{focus.name}</b>
                            <br />
                            <span className="text-small text-muted">
                              Total Construct: {focus.constructs.length}
                            </span>
                          </div>
                        </div>
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={24} sm={24} lg={12} xl={24} xxl={24}>
                <span className="font-smaller text-muted">Construct</span>
                <Select
                  value={selectedQuestionConstruct}
                  style={{ width: '100%' }}
                  onSelect={changeConstruct}
                  className="constructdropdown"
                  placeholder="Select Construct"
                >
                  {/* <Option>Select Construct</Option> */}
                  {construct.length > 0 &&
                    construct.map((focus: any, index: any) => (
                      <Option value={focus._id} key={focus._id}>
                        <div className="d-flex align-items-center">
                          <div
                            className="p-1 pb-0 pt-1"
                            style={{
                              backgroundColor: `${focus.color}`,
                              height: '30px',
                            }}
                          >
                            {focus.serial_number}
                          </div>
                          <div className="ms-2">
                            <b className="p-0 m-0">{focus.name}</b>
                            <p
                              className="text-small text-muted p-0 m-0"
                              style={{ fontSize: '10px', lineHeight: '10px' }}
                            >
                              {FocusAreaData.map((d: any) =>
                                d._id === selectedQuestionKeyFocusArea
                                  ? d.name
                                  : '',
                              )}
                            </p>
                          </div>
                        </div>
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} className="survey-mobile-header">
          <Button block className="mb-2 mobile_survey_btn" onClick={showModal}>
            Survey Configuration
          </Button>
        </Col>
        {/* xs={24} sm={24} md={24} lg={24} xl={19} */}
        {/* <div className="px-xl-2 surveu-form-container"> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={19} className="px-xl-2">
          <div className="border pb-3">
            <Row
              gutter={[16, 16]}
              className="p-3 justify-content-xl-between justify-content-lg-between justify-content-md-between"
            >
              <Col
                xs={16}
                sm={24}
                md={10}
                lg={10}
                xl={6}
                style={{ maxWidth: 'fit-content' }}
              >
                <div className="">
                  <h4>
                    <b>{projectDetail.name}</b>
                  </h4>
                </div>
              </Col>
              <Col xs={8} sm={24} md={4} lg={4} xl={2} className="mt-lg-2">
                <div>
                  {/* <RollbackOutlined style={{ marginRight: '15px' }} /> */}
                  <img
                    src={Undo}
                    alt="Undo"
                    className="w-15 cursor-pointer"
                    onClick={() => handleUndo()}
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Undo"
                  />
                  <img
                    src={Redo}
                    alt="Redo"
                    className="w-15 ms-3 cursor-pointer"
                    onClick={() => handleRedo()}
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Redu"
                  />
                  <img
                    src={InfoIcon}
                    alt="Info"
                    className="w-15 ms-3"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Info"
                  />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={15}
                className="d-flex justify-content-between justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
              >
                {/* <Row className="justify-content-xl-end justify-content-lg-end justify-content-xxl-end "> */}
                {/* <div className="d-flex justify-content-between justify-content-xl-end justify-content-xxl-end"> */}
                {/* <div> */}
                {/* <Col xs={16} sm={24} md={15} lg={11} xl={5}> */}
                <Button
                  className="btn"
                  style={{ marginRight: '15px' }}
                  onClick={() => {
                    generateArrayForMultiStepFrom()
                  }}
                >
                  Preview
                </Button>
                <Button
                  className="btn me-2"
                  disabled={loader}
                  onClick={() => addQuestionsTodb()}
                >
                  Save
                </Button>
                {/* {!isSurveyPublish && (
                  <Popconfirm
                    placement="leftTop"
                    title="Are you sure to publish this survey?"
                    onConfirm={() => fnpublishForm()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="btn btn-theme">Publish</Button>
                  </Popconfirm>
                )} */}
              </Col>
            </Row>
            <hr style={{ width: '98.5%', margin: '0 auto' }} />
            <Spin spinning={loader}>
              <div
                className="border block-containers p-xl-3 m-2 mb-2"
                onDragOver={(e: any) => dragOverOnBlock(e)}
              >
                <BlockForm
                  selectedBlock={selectedBlock}
                  setSelectedBlock={setSelectedBlock}
                  setSelectedQuestion={setSelectedQuestion}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestionType={setSelectedQuestionType}
                  setNumberOfChoice={setNumberOfChoice}
                  setMetrixtableScaleArrayLength={
                    setMetrixtableScaleArrayLength
                  }
                  setSelectedQuestionKeyFocusArea={
                    setSelectedQuestionKeyFocusArea
                  }
                  FocusAreaData={FocusAreaData}
                  setSelectedQuestionConstruct={setSelectedQuestionConstruct}
                  construct={construct}
                  handleMoveToTrash={handleMoveToTrash}
                  updateQuestionList={updateQuestionList}
                  questionList={questionList}
                  setQuestionList={setQuestionList}
                  addQuestionToList={addQuestionToList}
                  addQuestionsTodb={addQuestionsTodb}
                  metrixtableScaleArray={metrixtableScaleArray}
                  setQuestionAction={setQuestionAction}
                  setSelectedQuestionRaters={setSelectedQuestionRaters}
                  moveQuestion={moveQuestion}
                />
              </div>
            </Spin>
            <hr style={{ width: '98.5%', margin: '0 auto' }} />
          </div>
        </Col>
      </Row>
      <div
        className="drawer"
        style={{ display: isOpenDrawer ? 'block' : 'none' }}
      >
        <PublishableFrom
          visible={isOpenDrawer}
          setIsOpenDrawer={setIsOpenDrawer}
          questionList={questionList}
          multiStepFormContainer={multiStepFormContainer}
        />
      </div>
      {/*  modal for Survey configuration Mobile View */}
      <Modal
        visible={isModalVisible}
        closable={false}
        footer={null}
        className="survey-header-modal"
      >
        <Row className="Divider mb-2">
          <h3 className="py-1">Survey Configuration</h3>
        </Row>
        <Row gutter={[16, 16]} className="p-lg-3" justify="space-between">
          <Col xs={24} sm={24} lg={12}>
            <span className="font-smaller text-dark fw-bold">
              Question Type
            </span>
            <Select
              value={selectedQuestionType}
              style={{ width: '100%', height: '42px' }}
              onSelect={(e: any) => changeQuestionType(e)}
              className="select_config"
            >
              <Option value="multiple-choice">
                <div
                  className="d-flex align-items-center mt-1 mb-2"
                  style={{ lineHeight: '15px' }}
                >
                  <div className="p-1 pb-0 pt-0">
                    <img src={MultiChoiceIcon} alt="Multiple Choice" />
                  </div>
                  <div className="ms-2">
                    <b>Multiple Choice</b>
                    <br />
                    <span className="text-small text-muted">
                      Choose from a set of Question
                    </span>
                  </div>
                </div>
              </Option>
              <Option value="free-text">
                <div
                  className="d-flex align-items-center mt-1 mb-2"
                  style={{ lineHeight: '15px' }}
                >
                  <div className="p-1 pb-0 pt-0">
                    <img src={FreeTextIcon} alt="FreeTextIcon" />
                  </div>
                  <div className="ms-2">
                    <b>Free Text</b>
                    <br />
                    <span className="text-small text-muted">
                      Choose from a set of Question
                    </span>
                  </div>
                </div>
              </Option>
              <Option value="checkbox">
                <div
                  className="d-flex align-items-center mt-1 mb-2"
                  style={{ lineHeight: '15px' }}
                >
                  <div className="p-1 pb-0 pt-0">
                    <img src={CheckboxIcon} alt="CheckboxIcon" />
                  </div>
                  <div className="ms-2">
                    <b>Checkboxes</b>
                    <br />
                    <span className="text-small text-muted">
                      Choose from a set of Question
                    </span>
                  </div>
                </div>
              </Option>
              <Option value="dropdown">
                <div
                  className="d-flex align-items-center mt-1 mb-2"
                  style={{ lineHeight: '15px' }}
                >
                  <div className="p-1 pb-0 pt-0">
                    <img src={DropdownIcon} alt="DropdownIcon" />
                  </div>
                  <div className="ms-2">
                    <b>Dropdown</b>
                    <br />
                    <span className="text-small text-muted">
                      Choose from a set of Question
                    </span>
                  </div>
                </div>
              </Option>
              <Option value="matrix-table">
                <div
                  className="d-flex align-items-center mt-1 mb-2"
                  style={{ lineHeight: '15px' }}
                >
                  <div className="p-1 pb-0 pt-0">
                    <img src={MetrixTableIcon} alt="MetrixTableIcon" />
                  </div>
                  <div className="ms-2">
                    <b>Matrtix Table</b>
                    <br />
                    <span className="text-small text-muted">
                      Choose from a set of Question
                    </span>
                  </div>
                </div>
              </Option>
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            lg={12}
            style={{
              display:
                selectedQuestionType === 'matrix-table' ||
                selectedQuestionType === 'multiple-choice' ||
                selectedQuestionType === 'checkbox'
                  ? 'block'
                  : 'none',
            }}
          >
            <span className="font-smaller fw-bold text-dark">Answer type</span>
            <Select
              defaultValue="Select Answer type"
              style={{ width: '100%' }}
              onSelect={(e: any) => changeAnswertype(e)}
            >
              <Option value="allow-one">Allow one answer</Option>
              <Option value="allow-multiple">Allow Multiple</Option>
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            lg={12}
            className="choices-counter"
            style={{
              display:
                selectedQuestionType === 'matrix-table' ||
                selectedQuestionType === 'multiple-choice' ||
                selectedQuestionType === 'checkbox'
                  ? 'block'
                  : 'none',
            }}
          >
            <span className="font-smaller fw-bold text-dark">
              Number of choices
            </span>
            <Button className="ms-2" onClick={() => decrementChoiceConter()}>
              <MinusOutlined />
            </Button>
            <Input
              placeholder="03"
              value={numberOfChoice}
              className="w-25"
              disabled
            />
            <Button onClick={() => incrementChoiceConter()}>
              <PlusOutlined />
            </Button>
          </Col>
          <Col
            xs={24}
            sm={24}
            className="scale-counter"
            style={{
              display:
                selectedQuestionType === 'matrix-table' ? 'block' : 'none',
            }}
          >
            <span className="font-smaller text-dark">Scale Point</span>
            <Button
              className="ms-2"
              onClick={() => decrementScalePointConter()}
            >
              <MinusOutlined />
            </Button>
            <Input
              placeholder="03"
              value={metrixtableScaleArrayLength}
              className="w-25"
              disabled
            />
            <Button onClick={() => incrementScalePointConter()}>
              <PlusOutlined />
            </Button>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <span className="font-smaller fw-bold text-dark">
              Raters to ask
            </span>
            <Select
              style={{ width: '100%' }}
              defaultValue={selectedQuestionRaters}
              mode="multiple"
              allowClear
              onChange={changeRaters}
            >
              {raterlist.length > 0 &&
                raterlist.map((category: any) => (
                  <Option key={category._id} value={category._id}>
                    {category.title}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <span className="font-smaller fw-bold">Key focus area</span>
            <Select
              defaultValue={selectedQuestionKeyFocusArea}
              style={{ width: '100%' }}
              onSelect={changeKeyFocusArea}
              className="focusareadropdown"
            >
              {FocusAreaData.length > 0 &&
                FocusAreaData.map((focus: any, index: any) => (
                  <Option value={index} key={focus._id}>
                    <div
                      className="d-flex align-items-center mt-1 mb-2"
                      style={{ lineHeight: '15px' }}
                    >
                      <div className="p-1 pb-0 pt-0">
                        <img src={focus.image} alt="Icon" />
                      </div>
                      <div className="ms-2">
                        <b>{focus.name}</b>
                        <br />
                        <span className="text-small text-muted">
                          Total Construct: {focus.constructs.length}
                        </span>
                      </div>
                    </div>
                  </Option>
                ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <span className="font-smaller fw-bold">Construct</span>
            <Select
              defaultValue={selectedQuestionConstruct}
              style={{ width: '100%' }}
              onSelect={changeConstruct}
              className="constructdropdown"
            >
              {construct.length > 0 &&
                construct.map((focus: any, index: any) => (
                  <Option value={index} key={focus._id}>
                    <div className="d-flex align-items-center">
                      <div
                        className="p-1 pb-0 pt-1"
                        style={{
                          backgroundColor: `${focus.color}`,
                          height: '30px',
                        }}
                      >
                        {focus.serial_number}
                      </div>
                      <div className="ms-2">
                        <b className="p-0 m-0">{focus.name}</b>
                        <p
                          className="text-small text-muted p-0 m-0"
                          style={{ fontSize: '10px', lineHeight: '10px' }}
                        >
                          {FocusAreaData.map((d: any) =>
                            d._id === selectedQuestionKeyFocusArea
                              ? d.name
                              : '',
                          )}
                        </p>
                      </div>
                    </div>
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-2">
          <Button ghost onClick={handleCancel} className="cancelBtn me-2">
            Cancel
          </Button>
          <Button className="applyBtn">Apply</Button>
        </div>
      </Modal>
    </div>
  )
}
