import React, { useEffect, useState } from 'react'
import { Tabs, Radio, Space } from 'antd'
import Navbar from '../../components/Navbar'
import './style/settings.scss'
import { ChangePassword } from './components/ChangePassword'
import { UserProfile } from './components/UserProfile'
import { readCookie } from '../../api/CookieScript'
import {
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
} from '../../constants/index'
import { EmployeeProfile } from './components/EmployeeProfile'

const { TabPane } = Tabs

export const Settings: React.FC = (): JSX.Element => {
  return (
    <div className="min-85vh">
      <div className="settings-container h-90vh">
        <h3>Preferences</h3>
        <Tabs tabPosition="left" className="mt-3">
          {/* profile tab only display to employee & company */}
          {readCookie(ROLE_KEYWORD) !== SUPER_ADMIN_ROLE && (
            <TabPane tab="User Profile" key="1">
              {readCookie(ROLE_KEYWORD) === COMPANY_ROLE && <UserProfile />}
              {readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE && (
                <EmployeeProfile />
              )}
            </TabPane>
          )}
          <TabPane tab="Change Password" key="2">
            <ChangePassword />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
