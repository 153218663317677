/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import {
  Row,
  Col,
  Drawer,
  Button,
  Popconfirm,
  Modal,
  Select,
  notification,
  Spin,
  Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  // InsertRowRightOutlined,
  UserSwitchOutlined,
  QuestionCircleFilled,
  InfoCircleOutlined,
  SaveOutlined,
  FileOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import {
  getListOfAllTemplate,
  importMainTemplate,
  createTemplate,
  getCompanyList,
} from '../../api/API'
import { Anchor } from '../../components/Link/link'
// import image from '../../assets/images/girl-hold-books.jpeg'
import moreIcon from '../../assets/images/more.svg'
import userIcon from '../../assets/images/users.svg'
import { SurveyForm } from './components/surveyForm'
import DrawerFooter from '../../components/Drawer/footer/drawerFooter'
import empHeadIcon from '../../assets/images/Group 9011.svg'
import { ParticipantForm } from './components/participantForm'
import avatarimg from '../../assets/images/Icon awesome-user-circle.svg'
import arrowDown from '../../assets/images/arrow_down.svg'
import whitearw from '../../assets/images/Group Copy.svg'
import buildings from '../../assets/images/buildingdark.svg'
import UserIdIcon from '../../assets/images/Group 9264.svg'
import empIcon from '../../assets/images/Icon feather-users.svg'
import editIcon from '../../assets/images/Icon open-pencil.svg'
import modalIcon from '../../assets/images/Group 9367.svg'
import rightArwIcon from '../../assets/images/Icon ionic-ios-arrow-back.svg'
import arwDivider from '../../assets/images/Path 26578.svg'
import { readCookie } from '../../api/CookieScript'
import arrowDownWhite from '../../assets/images/arrow-down-white.svg'
import {
  COMPANY_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
} from '../../constants/index'

const { Option } = Select

interface Props {
  project: any
  getProjectDetailFn: any
}
const Header: React.FC<Props> = ({
  project,
  getProjectDetailFn,
}): JSX.Element => {
  const role = readCookie('role')
  const params = useParams<any>()
  const { id } = params // project id

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [participantDrawerVisible, setParticipantDrawerVisible] =
    useState(false)
  const [data, setData] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [draftModalVisible, setDraftModalVisible] = useState(false)
  const [moreTemplateModalVisible, setMoreTemplateModalVisible] =
    useState(false)
  const [moreDraftModalVisible, setMoreDraftModalVisible] = useState(false)

  const [listOFTemplate, setListOFTemplate] = useState([])
  const [selectedTemplateForApply, setSelectedTemplateForApply] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [templateName, setTemplateName] = useState('')
  const [errorLable, setErrorLable] = useState('')
  const [errorLableindicator, setErrorLableindicator] = useState(false)

  const [loading, setLoading] = useState(false)
  const [templateLoading, setTemplateLoading] = useState(false)

  const [selectTemplatErrorLable, setSelectTemplatErrorLable] = useState('')

  const [updateExistingTemplateModel, setUpdateExistingTemplateModel] =
    useState(false)

  const [listOfCompany, setListOfCompany] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState('')

  useEffect(() => {
    setData(project)
    getCompanyLists()
  }, [project])
  // const makeShortname = (): any => {
  //   if (name) {
  //     const fullName = name.split(' ')
  //     const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)
  //     return initials.toUpperCase()
  //   }
  // }
  const getCompanyLists = (): void => {
    setTemplateLoading(true)
    getCompanyList().then((res: any) => {
      setListOfCompany(res.data.data.reverse())
      setTemplateLoading(false)
    })
  }

  const selcetTemplateOfCompany = (e: any): void => {
    setTemplateLoading(true)
    const object = {
      projectid: id,
      company_id: e,
    }
    getListOfAllTemplate(object).then((res: any) => {
      setListOFTemplate(res.data.data)
      setTemplateLoading(false)
    })
  }

  const getTemplateList = (): void => {
    if (readCookie(ROLE_KEYWORD) === COMPANY_ROLE) {
      const object = {
        projectid: id,
      }
      getListOfAllTemplate(object).then((res: any) => {
        setListOFTemplate(res.data.data)
      })
    }
  }

  const applyFocusAreaTemplate = (): void => {
    setSelectTemplatErrorLable('')
    if (!selectedTemplateForApply) {
      setSelectTemplatErrorLable('Select Template')
    } else {
      const object = {
        project_id: id,
        template_id: selectedTemplateForApply,
      }
      // setConfirmLoading(true)
      setTemplateLoading(true)
      if (!confirmLoading) {
        setLoading(true)
        importMainTemplate(object).then((res: any) => {
          notification.success({
            message: 'Focus area template successfully Applyed',
          })
          // setConfirmLoading(false)
          setLoading(false)
          setMoreTemplateModalVisible(false)
          setTemplateLoading(false)
          window.location.reload()
        })
      }
    }
  }

  const newTemplate = (status: any): void => {
    if (templateName === '') {
      setErrorLable('Enter name !')
      setErrorLableindicator(true)
    } else {
      const obj = {
        project_id: id,
        name: templateName,
        status,
        action: 'add_new',
      }
      setLoading(true)
      createTemplate(obj).then((res: any) => {
        console.log('res=>', res)
        setLoading(false)
        if (res.data.code === 200) {
          setTemplateName('')
          setErrorLable('')
          setIsModalVisible(false)
          setErrorLableindicator(false)

          notification.open({
            message: 'Project successfully Added',
          })
        } else {
          setErrorLableindicator(true)
          setErrorLable('Template name already exist')
        }
      })
    }
  }

  const updateExistingTemplate = (status: any): void => {
    const obj = {
      project_id: id,
      status,
      action: 'update_existing',
    }
    createTemplate(obj).then((res: any) => {
      setUpdateExistingTemplateModel(false)
      notification.open({
        message: 'Template Successfully updated',
      })
    })
  }

  return (
    <>
      <Spin spinning={loading} className="spinner-code">
        <div className="project-detail-header">
          <Row align="middle" justify="space-between">
            <Col xs={24} sm={24} md={14} lg={4} xxl={4}>
              <Row>
                <Col
                  xs={24}
                  sm={12}
                  md={24}
                  lg={10}
                  xl={24}
                  id="project_name_row"
                >
                  <h5 className="text-uppercase header-name mb-0">
                    {project.name}
                  </h5>
                  <span className="mobile-header-name">
                    <b>{project.name}</b>
                  </span>
                  {/* info button display in mobile */}
                  <Popconfirm
                    title={
                      <div>
                        <Row
                          gutter={[12, 12]}
                          style={{ borderBottom: '2px solid #F4F6F7' }}
                          className="mobile_company_logo_name pb-2"
                        >
                          <Col xs={4} sm={4}>
                            <img
                              src={
                                project.companyData
                                  ? project.companyData[0].logo
                                  : ''
                              }
                              alt="not availabel"
                              className="company-logo mt-2"
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50px',
                              }}
                            />
                          </Col>
                          <Col xs={20} sm={20} md={20} className="pt-3">
                            <b>{project.name}</b>
                          </Col>
                        </Row>
                        <Row
                          gutter={[12, 12]}
                          className="mobile_project_id mt-3 justify-content-center"
                        >
                          <Col
                            xs={12}
                            sm={12}
                            className="border-end text-center"
                          >
                            <UserSwitchOutlined style={{ fontSize: '24px' }} />
                            {project.projectID}
                          </Col>
                          <Col xs={12} sm={12} className="text-center">
                            <img src={userIcon} alt="users" className="w-15" />
                            {/* {(project.employees ? project.employees.length : 0) +
                            (project.participant
                              ? project.participant.length
                              : 0)} */}
                            {project.totalEmployee + project.totalParticipant}
                            <QuestionCircleFilled
                              style={{ marginLeft: '6px' }}
                            />
                          </Col>
                        </Row>
                        <Row
                          gutter={[12, 12]}
                          className="mobile_project_person mt-3"
                        >
                          <Col
                            xs={12}
                            sm={12}
                            className="border-end text-center d-flex justify-content-center"
                          >
                            <img
                              src={project.logo || avatarimg}
                              alt="not availabel"
                              className="user-img"
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginRight: '8px',
                              }}
                            />
                            <p className="user-name">
                              {project.contact_person}
                            </p>
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            className="d-flex justify-content-center"
                          >
                            {/* <img
                            src={project.logo || avatarimg}
                            alt="not availabel"
                            className="user-img"
                            style={{
                              width: '30px',
                              height: '30px',
                              borderRadius: '30px',
                              marginRight: '8px',
                            }}
                          />
                          <p className="user-name">{EmployeeName}</p> */}
                          </Col>
                        </Row>
                      </div>
                    }
                    placement="bottom"
                    overlayStyle={{ width: '98vw' }}
                  >
                    <Button
                      id="info_button"
                      ghost
                      type="primary"
                      icon={<InfoCircleOutlined />}
                    >
                      info
                    </Button>
                  </Popconfirm>

                  {/* <DropdownButton
                      title={<img src={moreIcon} alt="More" className="w-75 bg-primary" />}
                      className="mobile-more-outlined"
                    >
                    <Dropdown.Item href="#/action-2">Select Template</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Save as Draft</Dropdown.Item>
                  </DropdownButton> */}
                  {/* more Button CODE OVER */}
                </Col>
              </Row>
              <div
                title={project.name}
                className="report-header-subtitle d-flex align-item-center text-truncate"
              >
                {/* <p className="p-0 mb-0"> */}
                <Anchor url="/dashboard" target="">
                  Dashboard
                </Anchor>
                {/* className="px-2" */}
                <img src={rightArwIcon} alt="Right" className="me-1 ms-1" />
                {/* <Anchor url="/myproject" target=""> */}
                <Link to={{ pathname: `/my-project/` }}>Project</Link>
                {/* </Anchor> */}
                <img src={rightArwIcon} alt="Right" className="me-1 ms-1" />
                {/* <Anchor url="/project-detail" target=""> */}
                <Link to={{ pathname: `/project-detail/${id}` }}>
                  {project.name}
                </Link>
                {/* </Anchor> */}
                {/* </p> */}
              </div>
              <hr className="mobile-hr" />
              <div className="d-flex mobile-dropdowns">
                {/* ADD USER DROP DOWN */}
                <Dropdown className="header-add-btns border-0">
                  <Dropdown.Toggle>
                    Add Users
                    <img
                      src={arrowDown}
                      alt="arrow"
                      className="dropdown-arrow"
                    />
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                      onClick={() => {
                        setDrawerVisible(true)
                      }}
                    >
                      <img
                        src={empHeadIcon}
                        alt="Employee"
                        className="w-15 pe-1 me-2"
                      />
                      Employee(s)
                    </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          setParticipantDrawerVisible(true)
                        }}
                      >
                        <img
                          src={empIcon}
                          alt="Participant(s)"
                          className="w-15 me-2"
                        />
                        Participant(s)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown.Toggle>
                </Dropdown>
                {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                  readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
                  <>
                    <Dropdown className="header-add-btn">
                      <Dropdown.Toggle className="border-0">
                        Save Template
                        {/* <img src={whitearw} alt="arrow" className="dropdown-arrow" /> */}
                        <img
                          src={arrowDownWhite}
                          alt="arrow"
                          className="arrow_down"
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setUpdateExistingTemplateModel(true)}
                          >
                            <img
                              src={editIcon}
                              alt="Update"
                              className="w-15 pe-1 me-2"
                            />
                            Update Existing
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setIsModalVisible(true)}
                          >
                            <span className="w-25 pe-1 me-2">
                              <SaveOutlined />
                            </span>
                            Save as New
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-3">
                      <span className="w-25 pe-1 me-2">
                        <SaveOutlined />
                      </span>
                      Save as Draft
                    </Dropdown.Item>
                    
                    

                    <Dropdown.Item
                      onClick={() => setUpdateExistingTemplateModel(true)}
                    >
                      <img
                        src={editIcon}
                        alt="Update"
                        className="w-15 pe-1 me-2"
                      />
                      Update Existing
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setIsModalVisible(true)}>
                      <span className="w-25 pe-1 me-2">
                        <SaveOutlined />
                      </span>
                      Save as New
                    </Dropdown.Item>
                    
                    
                    */}
                        </Dropdown.Menu>
                      </Dropdown.Toggle>
                    </Dropdown>
                    {/* MORE BUTTON DROP DOWN */}
                    <Dropdown className="header-more-btn">
                      <Dropdown.Toggle className="border-0">
                        <img
                          src={moreIcon}
                          alt="arrow"
                          className="dropdown-arrow"
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              getTemplateList()
                              setMoreTemplateModalVisible(true)
                            }}
                          >
                            Select Template
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-3">
                          Save as Draft
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </>
                )}
              </div>
            </Col>
            <img
              src={arwDivider}
              alt="divider"
              className="float-right custom-divider"
            />
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={4}
              xxl={3}
              className="border-right-dyn desktop-data ms-2"
            >
              <Row gutter={[8, 8]} justify="space-between">
                <Col xs={24} md={6}>
                  <img
                    src={
                      project.companyData ? project.companyData[0].logo : 'test'
                    }
                    alt="not availabel"
                    className="company-logo me-1"
                  />
                </Col>
                <Col xs={24} md={18}>
                  <b>{project.name}</b>
                  <p>
                    <img
                      src={buildings}
                      alt="ProjectID"
                      className="w-10 me-1"
                    />
                    {project.projectID}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={6}
              md={6}
              lg={2}
              xxl={2}
              className="text-center border-right-dyn desktop-data"
            >
              <img src={UserIdIcon} alt="ProjectID" className="w-12 me-1" />
              {project.projectID}
            </Col>
            <Col
              xs={24}
              sm={6}
              md={6}
              lg={2}
              xxl={2}
              className="text-center border-right-dyn desktop-data ps-0"
            >
              <img src={userIcon} alt="users" className="w-15" />{' '}
              {/* {(project.employees ? project.employees.length : 0) +
              (project.participant ? project.participant.length : 0)} */}
              {project.totalEmployee + project.totalParticipant}
              <Tooltip
                title="This is total number of employee and participants"
                className="ms-1"
              >
                <QuestionCircleFilled />
              </Tooltip>
            </Col>
            <Col
              xs={24}
              sm={6}
              md={6}
              lg={5}
              xxl={5}
              className={`${
                readCookie(ROLE_KEYWORD) !== EMPLOYEE_ROLE
                  ? 'pt-2 ms-3 desktop-data'
                  : 'border-right-dyns pt-2 ms-3 desktop-data'
              }`}
            >
              <Row>
                <Col xs={24} md={3}>
                  <img
                    src={project.logo || avatarimg}
                    alt="not availabel"
                    className="user-img"
                  />
                </Col>
                <Col xs={24} md={18}>
                  <p className="user-name ms-2">{project.contact_person}</p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={6}
              md={10}
              lg={24}
              xl={5}
              className="desk-buttons justify-content-xl-end"
            >
              <div className="justify-content-end button-collections d-flex">
                {/* d-flex  */}
                <DropdownButton
                  // id=""
                  // title="Add User"
                  title={
                    <span>
                      Add Users{' '}
                      <img src={arrowDown} alt="arrow" className="arrow_down" />
                    </span>
                  }
                  className="p-11 comman-button"
                >
                  {/* these 2 drop down display only to super admin and company user  */}
                  {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                    readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setDrawerVisible(true)
                        }}
                      >
                        <img
                          src={empHeadIcon}
                          alt="Employee"
                          className="w-15 pe-1 me-2"
                        />
                        Employee(s)
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setParticipantDrawerVisible(true)
                    }}
                  >
                    <img
                      src={empIcon}
                      alt="Participant(s)"
                      className="w-15 me-2"
                    />
                    Participant(s)
                  </Dropdown.Item>
                </DropdownButton>
                {/* these 2 drop down display only to super admin and company user  */}
                {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                  readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
                  <>
                    <DropdownButton
                      className="dropmenu-bg-primary comman-button ms-3"
                      title={
                        <span>
                          Save Template
                          <img
                            src={arrowDownWhite}
                            alt="arrow"
                            className="arrow_down"
                          />
                        </span>
                      }
                    >
                      <Dropdown.Item
                        onClick={() => setUpdateExistingTemplateModel(true)}
                      >
                        <img
                          src={editIcon}
                          alt="Update"
                          className="w-15 pe-1 me-2"
                        />
                        Update Existing
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setIsModalVisible(true)}>
                        <span className="w-25 pe-1 me-2">
                          <SaveOutlined />
                        </span>
                        Save as New
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={() => setDraftModalVisible(true)}>
                      <span className="w-25 pe-1 me-2">
                        <SaveOutlined />
                      </span>
                      Save as Draft
                    </Dropdown.Item> */}
                    </DropdownButton>
                    <DropdownButton
                      title={<img src={moreIcon} alt="More" />}
                      className="more-outlined ms-3 projectMoreBtn"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          getTemplateList()
                          setMoreTemplateModalVisible(true)
                        }}
                      >
                        <span className="w-25 pe-1 me-2">
                          <FileOutlined />
                        </span>
                        Select Template
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={() => setMoreDraftModalVisible(true)}>
                      <span className="w-25 pe-1 me-2">
                        <FileTextOutlined />
                      </span>
                      Save as Draft
                    </Dropdown.Item> */}
                    </DropdownButton>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <div className="drawer-wrapper">
            <Drawer
              onClose={() => {
                setDrawerVisible(false)
              }}
              visible={drawerVisible}
              title={
                <span>
                  <img
                    src={empHeadIcon}
                    alt="ADD/INVITE NEW EMPLOYEES"
                    className="drawer-head-icon me-3"
                  />
                  ADD/INVITE NEW EMPLOYEES
                </span>
              }
              width={window.innerWidth > 550 ? 465 : '100%'}
              footer={<DrawerFooter />}
            >
              <SurveyForm
                project={data}
                setDrawerVisible={setDrawerVisible}
                getProjectDetailFn={getProjectDetailFn}
              />
            </Drawer>
          </div>
          <div className="drawer-wrapper">
            <Drawer
              onClose={() => {
                setParticipantDrawerVisible(false)
              }}
              visible={participantDrawerVisible}
              title={
                <span>
                  <img
                    src={empHeadIcon}
                    alt="ADD/INVITE NEW EMPLOYEES"
                    className="drawer-head-icon me-3"
                  />
                  ADD/INVITE NEW RATER(S)
                </span>
              }
              width={window.innerWidth > 550 ? 465 : '100%'}
              footer={<DrawerFooter />}
            >
              <ParticipantForm
                project={data}
                setDrawerVisible={setParticipantDrawerVisible}
                participantDrawerVisible={participantDrawerVisible}
                getProjectDetailFn={getProjectDetailFn}
              />
            </Drawer>
          </div>
          {/* save as new modal  */}
          <Modal
            visible={isModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <div className="text-center">
              <img src={modalIcon} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">Save this New template 5</p>
              <input
                type="text"
                placeholder="Name of Template"
                className="mb-3 w-75 templateName"
                value={templateName}
                onChange={(e: any) => setTemplateName(e.target.value)}
              />
              {errorLableindicator && (
                <p style={{ color: 'red' }}>{errorLable}</p>
              )}
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="ghost bg-transparent border-rounded cancelButton"
                  onClick={() => {
                    setIsModalVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2 saveButton"
                  onClick={() => {
                    newTemplate('publish')
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
          {/* draft modal  */}
          <Modal
            visible={draftModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <div className="text-center">
              <img src={modalIcon} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">Save as Draft</p>
              <input
                type="text"
                placeholder="Name of Draft"
                className="mb-3 w-75 templateName"
                onChange={(e: any) => setTemplateName(e.target.value)}
              />
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="ghost bg-transparent border-rounded cancelButton"
                  onClick={() => {
                    newTemplate('draft')
                    setDraftModalVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2 saveButton"
                  onClick={() => {
                    setDraftModalVisible(false)
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
          {/* more button select template modal  */}
          <Modal
            visible={moreTemplateModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <Spin spinning={templateLoading} className="spinner-code">
              <div className="text-center">
                <img src={modalIcon} alt="Confirm" />
                <p className="mt-3 mb-3 fw-bold">Import Project Template</p>
                {readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE && (
                  <Select
                    className="mb-3"
                    style={{ width: 300 }}
                    onChange={(e: any) => {
                      selcetTemplateOfCompany(e)
                      setSelectedTemplate('')
                    }}
                    placeholder="Select Company"
                  >
                    {listOfCompany.map((company: any) => (
                      <Option value={company._id}>{company.name}</Option>
                    ))}
                  </Select>
                )}

                <Select
                  className="mb-3"
                  style={{ width: 300 }}
                  onChange={(e: any) => {
                    setSelectedTemplateForApply(e)
                    setSelectedTemplate(e)
                  }}
                  placeholder="Select Template"
                  defaultValue={selectedTemplate}
                  key={selectedTemplate}
                >
                  {listOFTemplate.map((tempatates: any) => (
                    <Option value={tempatates._id}>{tempatates.name}</Option>
                  ))}
                </Select>
                {selectTemplatErrorLable !== '' && (
                  <p className="text-danger">{selectTemplatErrorLable}</p>
                )}
                <div className="d-flex w-100 justify-content-center">
                  <Button
                    className="ghost w-25 bg-transparent border-rounded cancelButton"
                    onClick={() => {
                      setMoreTemplateModalVisible(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Popconfirm
                    title={
                      <div>
                        Are you sure?
                        <br /> you want to apply this template
                        <br /> beacuse this will replace old data
                        <br />
                        <b className="text-danger">
                          you have to reassign User/Participant designation
                          manually
                        </b>
                      </div>
                    }
                    okText="Yes"
                    onConfirm={applyFocusAreaTemplate}
                    cancelText="No"
                  >
                    <Button className="bg-theme-save-framwork-popup w-25 ms-2 saveButton">
                      Apply
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </Spin>
          </Modal>
          {/* more button save draft modal  */}
          <Modal
            visible={moreDraftModalVisible}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <div className="text-center">
              <img src={modalIcon} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">Save as Draft</p>
              <input
                type="text"
                placeholder="Name of Draft"
                className="mb-3 w-75 templateName"
              />
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="ghost bg-transparent border-rounded cancelButton"
                  onClick={() => {
                    setMoreDraftModalVisible(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2 saveButton"
                  onClick={() => {
                    setMoreDraftModalVisible(false)
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>

          {/* update existing template */}
          <Modal
            visible={updateExistingTemplateModel}
            centered
            footer={null}
            width={400}
            closable={false}
            className="templateModal"
          >
            <div className="text-center">
              <img src={modalIcon} alt="Confirm" />
              <p className="mt-3 mb-3 fw-bold">
                Update Current Applyed Template
              </p>
              <p>
                Are you sure to update current template because it&lsquo;s
                replace old data{' '}
              </p>
              <div className="d-flex w-100 justify-content-center">
                <Button
                  className="ghost bg-transparent border-rounded cancelButton"
                  onClick={() => {
                    setUpdateExistingTemplateModel(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-theme-save-framwork-popup ms-2 saveButton"
                  onClick={() => {
                    updateExistingTemplate('publish')
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </>
  )
}

export default Header
