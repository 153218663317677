/* eslint-disable prettier/prettier */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react'
import { Table, Checkbox, Typography } from 'antd'
import { Link } from 'react-router-dom'
import {
  CopyOutlined,
  CheckOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Doughnut, defaults } from 'react-chartjs-2'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import './styles/listview.scss'
import settings from '../../../../assets/images/Group 9363.svg'
import more from '../../../../assets/images/more.svg'

const { Paragraph } = Typography

interface Props {
  company: any
  list: any
  deleteProject: any
  changeMode: any
}
export const ProjectListView: React.FC<Props> = ({
  company,
  list,
  deleteProject,
  changeMode,
}): JSX.Element => {
  const [companyData, setcompanyData] = useState(company)
  const [projectList, setProjectList] = useState()

  useEffect(() => {
    setcompanyData(company)
    setProjectList(list)
    // console.log(list)
  }, [])

  const chartFn = (completed: any, notCompleted: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, 100 - completed],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const columns = [
    // {
    //   dataIndex: 'active',
    //   key: 'active',
    //   width: '1%',
    //   render: () => {
    //     return <div className="project-active-dot"> </div>
    //   },
    // },
    // pass id with route
    {
      title: 'PROJECT NAME',
      dataIndex: 'name',
      key: 'name',
      width: '17%',
      render: (text: any, record: any) => (
        <div className="d-flex justify-content-start align-items-center">
          <div
            className={
              record.mode ? 'project-active-dot' : 'project-deactive-dot'
            }
          >
            {' '}
          </div>
          <span className="tab-item ms-2">
            <Link to={{ pathname: `/project-detail/${record._id}` }}>
              <span
                style={{
                  textDecoration: record.mode ? 'none' : 'line-through',
                }}
              >
                {record.name}
              </span>
            </Link>
          </span>
        </div>
      ),
      description: 'My name is ',
    },
    {
      title: 'PROJECT ID',
      dataIndex: 'id',
      key: 'id',
      width: '6%',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">
          {record.projectID != null ? record.projectID : '0'}
        </h5>
      ),
    },
    {
      title: 'EMPLOYEES',
      key: 'employees',
      dataIndex: 'employees',
      width: '7%',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">
          {record.employees != null ? record.employees.length : '0'}
        </h5>
      ),
    },
    {
      title: 'REGISTERED BY',
      key: 'reg',
      dataIndex: 'reg',
      width: '7%',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">
          {record.contact_person != null ? record.contact_person : ' '}
        </h5>
      ),
    },
    {
      title: 'REGISTERED DATE',
      dataIndex: 'date',
      key: 'date',
      width: '8%',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">
          {record.created_at != null ? record.created_at.split('T')[0] : '0'}
        </h5>
      ),
    },
    {
      title: 'EMAIL',
      key: 'email',
      dataIndex: 'email',
      width: '12%',
      render: (text: any, record: any) => (
        <div className="copy-icon">
          {record.email && (
            <Paragraph
              className="mb-0"
              copyable={{
                icon: [
                  <CopyOutlined key="copy-icon" />,
                  <CheckOutlined key="copied-icon" />,
                ],
                tooltips: ['copy', 'copied!!'],
              }}
            >
              {record.email}
            </Paragraph>
          )}
        </div>
      ),
    },
    // {
    //   title: 'PHONE',
    //   key: 'phone',
    //   dataIndex: 'phone',
    //   width: '11%',
    //   render: (text: any, record: any) => (
    //     <div className="copy-icon">
    //       {record.phone && (
    //         <Paragraph
    //           className="mb-0"
    //           copyable={{
    //             icon: [
    //               <CopyOutlined key="copy-icon" />,
    //               <CheckOutlined key="copied-icon" />,
    //             ],
    //             tooltips: ['copy', 'copied!!'],
    //           }}
    //         >
    //           {record.phone}
    //         </Paragraph>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: 'RATERS',
      key: 'tickets',
      dataIndex: 'tickets',
      width: '5%',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">
          {record.participant != null ? record.participant.length : '0'}
        </h5>
      ),
    },
    {
      title: 'SURVEY',
      key: 'survey',
      dataIndex: 'survey',
      width: '5%',
      render: (text: any, record: any) => {
        return (
          <div className="card1-chart-wrapper">
            <div className="label-wrapper ps-1">
              <span>{Math.round(record.completedSurveyCount)}</span>
            </div>
            <div className="chart-wrapper">
              <Doughnut
                data={chartFn(
                  Math.round(record.completedSurveyCount),
                  Math.round(record.notCompletedSurveyCount),
                )}
              />
            </div>
          </div>
        )
      },
    },
    {
      title: 'Contact Person',
      key: 'contact_person',
      dataIndex: 'contact_person',
      width: '4%',
      render: (text: any, record: any) => (
        <h5 className="tab-item mb-0">{record.contact_person}</h5>
      ),
    },
    {
      render: (text: any, record: any) => (
        <div className="d-flex justify-content-end align-items-center">
          {/* <img
            height="30px"
            width="30px"
            src={settings}
            alt="settings"
            className="me-3"
          /> */}
          {/* <img
            height="20px"
            width="20px"
            src={more}
            alt="arrow"
            className="me-3"
          /> */}
          <div className="company-project-list-more-button">
            <DropdownButton
              title={
                <img
                  src={more}
                  alt="Menu"
                  className="me-3 p-0"
                  height="20px"
                  width="20px"
                />
              }
              className="more-outlined p-0 border-0 m-0 setting-dropdown"
            >
              <Link
                to={{ pathname: '/project-detail', state: record._id }}
                className="dropdown-item"
              >
                <span className="pe-2">
                  <EyeOutlined />
                </span>
                View
              </Link>
              <Link
                to={{ pathname: '/project-detail', state: record._id }}
                className="dropdown-item"
              >
                <span className="pe-2">
                  <EditOutlined />
                </span>
                Edit
              </Link>
              <Dropdown.Item onClick={() => deleteProject(record._id)}>
                <span className="pe-2">
                  <DeleteOutlined />
                </span>
                Delete
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeMode(record._id, !record.mode)}
              >
                <span
                  className={record.mode ? 'deactivate-dot' : 'activate-dot'}
                />
                {record.mode ? 'Deactivate' : 'Active'}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      ),
    },
  ]

  const [state, setState] = useState({ selectedRowKeys: [] })
  const onSelectChange = (selectedRowKeys: any): any => {
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    setState({ selectedRowKeys })
  }

  const { selectedRowKeys } = state
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: any): any => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter(
            (key: any, index: any) => {
              if (index % 2 !== 0) {
                return false
              }
              return true
            },
          )
          setState({ selectedRowKeys: newSelectedRowKeys })
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: any): any => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter(
            (key: any, index: any) => {
              if (index % 2 !== 0) {
                return true
              }
              return false
            },
          )
          setState({ selectedRowKeys: newSelectedRowKeys })
        },
      },
    ],
  }

  return (
    <div id="projectlist_table">
      <Table
        size="small"
        columns={columns}
        // rowSelection={rowSelection}
        dataSource={projectList}
        className="tabledesign"
        pagination={false}
        rowKey="_id"
        // expandIcon={({ expanded, onExpand, record }) =>
        //   expanded ? (
        //     <UpCircleOutlined onClick={(e) => onExpand(record, e)} />
        //   ) : (
        //     <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
        //   )
        // }
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <p style={{ margin: 0 }}>{record.name}</p>
        //   ),
        //   expandIconColumnIndex: 12,
        // }}
      />
    </div>
  )
}
