/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import validator from 'validator'
import {
  notification,
  Button,
  Drawer,
  Alert,
  Form,
  Input,
  DatePicker,
  Select,
} from 'antd'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
// import Button from '../../../../../components/Button'
import './styles/detailForm.scss'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import Camera from '../../../../../assets/images/camera.svg'
import Edit from '../../../../../assets/images/edit.svg'
import { updateCompany, getCompany } from '../../../../../api/API'
import 'bootstrap/dist/css/bootstrap.min.css'

interface Props {
  company: any
}
export const CompanyDetailForm: React.FC<Props> = ({
  company,
}): JSX.Element => {
  const [data, setData] = useState(company)
  const [name, setname] = useState(company.name)
  const [contact, setcontact] = useState(company.contact_person)
  const [email, setemail] = useState(company.email)
  const [phone, setphone] = useState(company.phone)
  const [abn, setabn] = useState(company.abn)
  const [country, setcountry] = useState(company.country)
  const [state, setstate] = useState(company.state)
  const [city, setcity] = useState(company.city)
  const [add1, setadd1] = useState(company.address_line1)
  const [add2, setadd2] = useState(company.address_line2)
  const [pin, setpin] = useState(company.zip_code)
  const [industry, setindustry] = useState(company.industry)
  const [employee, setemployee] = useState(company.number_of_employee)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('')
  const [logo, setlogo] = useState<any>()
  const [rerenderState, setRerenderState] = useState(Math.random())

  const [priviewImg, setPriviewImg] = useState<any>()

  // const location = useLocation()
  const params = useParams<any>()
  const { Option } = Select

  useEffect(() => {
    setData(company)
    const { id } = params // project id
    getCompany(id).then((res: any) => {
      setData(res.data.data[0])
      setname(res.data.data[0].name)
      setemail(res.data.data[0].email)
      setcontact(res.data.data[0].contact_person)
      setphone(res.data.data[0].phone)
      setabn(res.data.data[0].abn)
      setcountry(res.data.data[0].country)
      setcity(res.data.data[0].city)
      setindustry(res.data.data[0].industry)
      setstate(res.data.data[0].state)
      setadd1(res.data.data[0].address_line1)
      setadd2(res.data.data[0].address_line2)
      setpin(res.data.data[0].zip_code)
      setemployee(res.data.data[0].number_of_employee)
      setlogo(res.data.data[0].logo)
    })
  }, [])
  const handleSubmit = (): void => {
    const formData = new FormData()
    formData.append('logo', logo)
    formData.append('name', name)
    formData.append('contact_person', contact)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('abn', abn)
    formData.append('country', country)
    formData.append('state', state)
    formData.append('city', city)
    formData.append('address_line1', add1)
    formData.append('address_line2', add2)
    formData.append('zip_code', pin)
    formData.append('industry', industry)
    // formData.append('employee', employee)
    formData.append('number_of_employee', employee)
    // eslint-disable-next-line no-underscore-dangle
    if (phone) {
      // eslint-disable-next-line no-underscore-dangle
      updateCompany(formData, data._id).then((res: any) => {
        const result = res.data
        notification.success({
          message: result.message,
          description: '',
        })
        setIsDrawerVisible(false)
      })
    }
  }

  const imagePreview = (e: any): void => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPriviewImg(objectUrl)
    setlogo(e.target.files[0])
  }

  const checkValidation = (value: any): void => {
    if (!validator.isMobilePhone(value)) {
      setPhoneErrorMsg('Enter Valid phone number.!')
    } else {
      setPhoneErrorMsg('')
    }
  }

  return (
    <div className="company-detail-wrapper">
      <div className="company-header p-xs-0">
        <div className="company-desc">
          <span className="text1">Company Details</span>
          <span className="text2">
            Find the below details for the added company
          </span>
        </div>
        <Button className="edit-btn" onClick={() => setIsDrawerVisible(true)}>
          <img src={Edit} alt="edit" className="edit-img" />
          <span className="btn-text">Edit</span>
        </Button>
      </div>
      <div className="form-container">
        <div className="add-company-row1">
          <div className="profile-photo">
            <div className="profile-circle">
              {/* <img src={data.logo} alt="dd" /> */}
              <img src={data.logo} alt="dd" />
            </div>
            {/* <div className="input-form"> */}
            {/* <label htmlFor="profile-input">
              <div className="cam-circle">
                <img src={Camera} alt="Camera" />
              </div>
            </label> */}
            {/* <input
              type="file"
              className="profile-input"
              id="profile-input"
              onChange={(e: any) => imagePreview(e)}
            /> */}
            {/* </div> */}
          </div>
          <div className="profile-desc">
            <span>
              Please make sure the logo file must be less than 1mb and in a
              dimension of 150x150
            </span>
          </div>
        </div>
        <form>
          <Row className="form-row">
            <Col
              // xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              // xxl={6}
              className="form-group"
            >
              <label htmlFor="inputName4" className="form-label">
                Name
              </label>
              <input
                type="name"
                className="form-control"
                id="inputName4"
                placeholder="Please Enter Company Name"
                defaultValue={name}
                // onChange={(e: any) => {
                //   setname(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              // xxl={3}
              className="form-group"
            >
              <label htmlFor="inputContactPerson" className="form-label">
                Contact Person
              </label>
              <input
                type="name"
                className="form-control"
                id="inputContactPerson"
                placeholder="Enter Name"
                value={contact}
                // onChange={(e: any) => {
                //   setcontact(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              // xxl={3}
              className="form-group"
            >
              <label htmlFor="inputEmail4" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Enter Email"
                value={email}
                readOnly
                disabled
                // onChange={(e: any) => {
                //   setemail(e.target.value)
                // }}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col
              // xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              // xxl={3}
              className="form-group"
            >
              <label htmlFor="inputPhoneNumber" className="form-label">
                Phone
              </label>
              <input
                type="name"
                className="form-control"
                id="inputPhoneNumber"
                placeholder="Enter Phone Number"
                value={phone}
                // onChange={(e: any) => {
                //   setphone(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              // xxl={3}
              className="form-group"
            >
              <label htmlFor="inputABN" className="form-label">
                ABN
              </label>
              <input
                type="name"
                className="form-control"
                id="inputABN"
                placeholder="Enter ABN"
                value={abn}
                // onChange={(e: any) => {
                //   setabn(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              // xxl={3}
              className="form-group"
            >
              <label htmlFor="inputIndustry" className="form-label">
                Industry
              </label>
              <input
                type="name"
                className="form-control"
                id="inputIndustry"
                placeholder="Enter Industry"
                value={industry}
                // onChange={(e: any) => {
                //   setindustry(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              // xxl={3}
              className="form-group"
            >
              <label htmlFor="inputCountry" className="form-label">
                Country
              </label>
              <input
                type="name"
                className="form-control"
                id="inputCountry"
                placeholder="Enter Country"
                value={country}
                // onChange={(e: any) => {
                //   setcountry(e.target.value)
                // }}
                readOnly
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col
              // xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              // xxl={6}
              className="form-group"
            >
              <label htmlFor="inputState" className="form-label">
                State
              </label>

              <input
                type="name"
                className="form-control"
                id="inputState"
                placeholder="Enter State"
                value={state}
                // onChange={(e: any) => {
                //   setstate(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              // xxl={6}
              className="form-group"
            >
              <label htmlFor="inputCity" className="form-label">
                City
              </label>
              <input
                type="name"
                className="form-control"
                id="inputCity"
                placeholder="Enter City"
                value={city}
                // onChange={(e: any) => {
                //   setcity(e.target.value)
                // }}
                readOnly
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col
              // xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              // xxl={6}
              className="form-group"
            >
              <label htmlFor="inputAddress1" className="form-label">
                Address Line 1
              </label>
              <input
                type="name"
                className="form-control"
                id="inputAddress1"
                placeholder="Enter Address"
                value={add1}
                // onChange={(e: any) => {
                //   setadd1(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="form-group"
            >
              <label htmlFor="inputAddress2" className="form-label">
                Address Line 2
              </label>
              <input
                type="name"
                className="form-control"
                id="inputAddress2"
                placeholder="Enter Address"
                value={add2}
                // onChange={(e: any) => {
                //   setadd2(e.target.value)
                // }}
                readOnly
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="form-group">
              <label htmlFor="inputPinCode" className="form-label">
                Zip/Pin Code
              </label>
              <input
                type="name"
                className="form-control"
                id="inputPinCode"
                placeholder="Enter Zip/Pin Code"
                value={pin}
                // onChange={(e: any) => {
                //   setpin(e.target.value)
                // }}
                readOnly
              />
            </Col>
            <Col
              // xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="form-group"
            >
              <label htmlFor="inputEmp" className="form-label">
                Employees
              </label>
              <input
                type="name"
                className="form-control"
                id="inputEmp"
                placeholder="Enter Number of Employee"
                value={employee}
                // onChange={(e: any) => {
                //   setemployee(e.target.value)
                // }}
                readOnly
              />
            </Col>
          </Row>
        </form>
      </div>
      {/* Edit Company Drawer */}
      <Drawer
        title={
          <p>
            <EditOutlined className="me-1" />
            Edit Company Detail
          </p>
        }
        visible={isDrawerVisible}
        // onOk={handleAddProject}
        // onCancel={handleCancel}
        closeIcon={<CloseOutlined onClick={() => setIsDrawerVisible(false)} />}
        // okText="Save"
        width={window.innerWidth > 550 ? 465 : '100%'}
        className="ProjectModal"
      >
        <div className="form-container company-update-form">
          <Form
            className="mt-4 px-3"
            onFinish={handleSubmit}
            key={rerenderState}
          >
            <div className="add-company-row1">
              <div className="profile-photo ms-3">
                <div className="profile-circle">
                  <img src={priviewImg ? priviewImg : data.logo} alt="dd" />
                </div>
                <div className="input-form">
                  <label htmlFor="profile-input">
                    <div className="cam-circle">
                      <img src={Camera} alt="Camera" />
                    </div>
                  </label>
                  <Form.Item
                    name="logo"
                    // initialValue={logo}
                    // rules={[{ required: true, message: 'Select Image' }]}
                  >
                    <Input
                      type="file"
                      className="profile-input"
                      id="profile-input"
                      onChange={(e: any) => imagePreview(e)}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="profile-desc">
                <span>
                  Please make sure the logo file must be less than 1mb and in a
                  dimension of 150x150
                </span>
              </div>
            </div>
            <Alert
              message={errorMsg}
              type="error"
              style={{ display: isError ? 'block' : 'none' }}
            />
            <Row className="form-row">
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={6}
                className="form-group"
              >
                <label htmlFor="inputCompany" className="form-label">
                  Name
                  <span className="asterik">*</span>
                </label>
                <Form.Item
                  name="name"
                  initialValue={name}
                  rules={[{ required: true, message: 'Enter Company Name' }]}
                >
                  <Input
                    className="form-control"
                    id="inputName4"
                    onChange={(e: any) => setname(e.target.value)}
                    placeholder="Please Enter Company Name"
                    // defaultValue={name}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={3}
                className="form-group"
              >
                <label htmlFor="inputContactPerson" className="form-label">
                  Contact Person
                  <span className="asterik">*</span>
                </label>
                <Form.Item
                  name="contactPerson"
                  initialValue={contact}
                  rules={[{ required: true, message: 'Enter Contact Person' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputContactPerson"
                    placeholder="Enter Name"
                    // defaultValue={contact}
                    onChange={(e: any) => {
                      setcontact(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={12}
                xl={12}
                // xxl={3}
                className="form-group"
              >
                <label htmlFor="inputEmail4" className="form-label">
                  Email
                  <span className="asterik">*</span>
                </label>
                <Form.Item name="email">
                  <Input
                    // type="email"
                    className="form-control"
                    id="inputEmail4"
                    placeholder="Enter Email"
                    defaultValue={email}
                    readOnly
                    disabled
                    // onChange={(e: any) => {
                    //   setemail(e.target.value)
                    // }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="form-row">
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={3}
                className="form-group"
              >
                <label htmlFor="inputPhoneNumber" className="form-label">
                  Phone
                  <span className="asterik">*</span>
                </label>
                <Form.Item
                  key={rerenderState}
                  name="phone"
                  initialValue={phone}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: phoneErrorMsg
                  //       ? phoneErrorMsg
                  //       : 'Enter Phone Number',
                  //   },
                  // ]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputPhoneNumber"
                    placeholder="Enter Phone Number"
                    // defaultValue={phone}
                    onChange={(e: any) => {
                      setphone(e.target.value)
                      checkValidation(e.target.value)
                    }}
                  />
                </Form.Item>
                {phoneErrorMsg && (
                  <p style={{ color: 'red' }}>{phoneErrorMsg}</p>
                )}
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={3}
                className="form-group"
              >
                <label htmlFor="inputABN" className="form-label">
                  ABN
                  <span className="asterik">*</span>
                </label>
                <Form.Item
                  name="abn"
                  initialValue={abn}
                  rules={[{ required: true, message: 'Enter ABN' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputABN"
                    placeholder="Enter ABN"
                    // defaultValue={abn}
                    onChange={(e: any) => {
                      setabn(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={3}
                className="form-group"
              >
                <label htmlFor="inputIndustry" className="form-label">
                  Industry
                  {/* <span className="asterik">*</span> */}
                </label>
                <Form.Item
                  name="industry"
                  initialValue={industry}
                  // rules={[{ required: true, message: 'Enter Industry' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputIndustry"
                    placeholder="Enter Industry"
                    // defaultValue={industry}
                    onChange={(e: any) => {
                      setindustry(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={3}
                className="form-group"
              >
                <label htmlFor="inputCountry" className="form-label">
                  Country
                  {/* <span className="asterik">*</span> */}
                </label>
                <Form.Item
                  name="country"
                  initialValue={country}
                  // rules={[{ required: true, message: 'Enter Country' }]}
                >
                  <CountryDropdown
                    defaultOptionLabel={country}
                    value={country}
                    onChange={(e: any) => {
                      setcountry(e)
                    }}
                    classes="form-control"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="form-row">
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={6}
                className="form-group"
              >
                <label htmlFor="inputState" className="form-label">
                  State
                </label>
                {/* <span className="asterik">*</span> */}
                <Form.Item
                  name="state"
                  initialValue={state}
                  // rules={[{ required: true, message: 'Enter State' }]}
                >
                  {/* <Input
                    // type="name"
                    className="form-control"
                    id="inputState"
                    placeholder="Enter State"
                    defaultValue={state}
                    onChange={(e: any) => {
                      setstate(e.target.value)
                    }}
                  /> */}
                  <RegionDropdown
                    blankOptionLabel="No country selected"
                    defaultOptionLabel={state}
                    country={country}
                    value={state}
                    onChange={(e: any) => {
                      setstate(e)
                    }}
                    classes="form-control"
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={6}
                className="form-group"
              >
                <label htmlFor="inputCity" className="form-label">
                  City
                  {/* <span className="asterik">*</span> */}
                </label>
                <Form.Item
                  name="city"
                  initialValue={city}
                  // rules={[{ required: true, message: 'Enter City' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputCity"
                    placeholder="Enter City"
                    // defaultValue={city}
                    // value={city}
                    onChange={(e: any) => {
                      setcity(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="form-row">
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="form-group"
              >
                <label htmlFor="inputAddress1" className="form-label">
                  Address Line 1{/* <span className="asterik">*</span> */}
                </label>
                <Form.Item
                  name="address1"
                  initialValue={add1}
                  // rules={[{ required: true, message: 'Enter Address' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputAddress1"
                    placeholder="Enter Address"
                    // defaultValue={add1}
                    onChange={(e: any) => {
                      setadd1(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="form-group"
              >
                <label htmlFor="inputAddress2" className="form-label">
                  Address Line 2{/* <span className="asterik">*</span> */}
                </label>
                <Form.Item
                  name="address2"
                  initialValue={add2}
                  // rules={[{ required: true, message: 'Enter Address' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputAddress2"
                    placeholder="Enter Address"
                    // defaultValue={add2}
                    onChange={(e: any) => {
                      setadd2(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="form-row">
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="form-group">
                <label htmlFor="inputPinCode" className="form-label">
                  Zip/Pin Code
                  {/* <span className="asterik">*</span> */}
                </label>
                <Form.Item
                  name="zip"
                  initialValue={pin}
                  // rules={[{ required: true, message: 'Enter Zip/Pin Code' }]}
                >
                  <Input
                    // type="name"
                    className="form-control"
                    id="inputPinCode"
                    placeholder="Enter Zip/Pin Code"
                    // defaultValue={pin}
                    onChange={(e: any) => {
                      setpin(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                // xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                // xxl={12}
                className="form-group"
              >
                <label htmlFor="inputEmp" className="form-label">
                  Employees
                </label>
                <Form.Item
                  name="employee"
                  initialValue={employee}
                  // rules={[
                  //   { required: true, message: 'Enter Number of Employee' },
                  // ]}
                >
                  <Input
                    type="number"
                    className="form-control"
                    id="inputEmp"
                    min="0"
                    placeholder="Enter Number of Employee"
                    // defaultValue={employee}
                    onChange={(e: any) => {
                      setemployee(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex justify-content-end pt-4 pb-3 pe-3">
              <Button
                onClick={() => setIsDrawerVisible(false)}
                className="float-right me-2 cancel-btn"
              >
                Cancel
              </Button>
              {/* <Button onClick={handleSubmit} className="float-right save-btn">
                Save
              </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                className="float-right save-btn"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  )
}
