/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import '../../../style/project.scss'
import { Row, Col, Checkbox, Button, Modal } from 'antd'
import {
  MoreOutlined,
  UserOutlined,
  ContainerOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Doughnut, defaults } from 'react-chartjs-2'
import userCountImg from '../../../../../assets/images/Icon feather-users.svg'
import moreIcon from '../../../../../assets/images/Icon feather-more-vertical.svg'
import listImg from '../../../../../assets/images/Icon awesome-tasks.png'
import PopupConfirm from '../../../../../assets/images/Group 9366.svg'
import { readCookie } from '../../../../../api/CookieScript'
import {
  COMPANY_ROLE,
  EMPLOYEE_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
} from '../../../../../constants/index'

interface Props {
  projectList?: any
  deleteProject: any
  changeMode: any
}
const chartData = {
  datasets: [
    {
      data: [80, 20],
      backgroundColor: ['#008174', 'transparent'],
      hoverOffset: 0,
    },
  ],
}

export const ProjectGrid: React.FC<Props> = ({
  projectList,
  deleteProject,
  changeMode,
}): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [ProjectIdForDelete, setProjectIdForDelete] = useState('')

  const chartFn = (completed: any, notCompleted: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, 100 - completed],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  const option = {
    plugins: {
      tooltip: {
        callbacks: {
          title() {
            return 'my tittle'
          },
        },
      },
      legend: { display: false },
      title: {
        display: true,
        text: 'Test chart',
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <div className="project-grid-view">
      <Row gutter={[6, 6]}>
        {projectList.length > 0 &&
          projectList.map((data: any, index: any) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6} key={data._id}>
              <div
                className={
                  data.mode
                    ? 'activate-card-bg cursor-pointer border p-2 user_card'
                    : 'deactivate-card-bg cursor-pointer border p-2 user_card'
                }
              >
                <Row>
                  <Col xs={23} lg={23} xl={23} md={12}>
                    {/* <Checkbox name="selected" /> */}
                  </Col>
                  <Col
                    xs={1}
                    lg={1}
                    xl={1}
                    md={12}
                    className="text-end usercard-dropdown"
                  >
                    {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                      readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
                      <DropdownButton
                        title={<MoreOutlined />}
                        className="more-outlined"
                      >
                        <Link
                          to={{ pathname: `/project-detail/${data._id}` }}
                          className="dropdown-item"
                        >
                          <span className="pe-2">
                            <EyeOutlined />
                          </span>
                          View
                        </Link>
                        <Link
                          to={{ pathname: `/project-detail/${data._id}` }}
                          className="dropdown-item"
                        >
                          <span className="pe-2">
                            <EditOutlined />
                          </span>
                          Edit
                        </Link>
                        {/* <Dropdown.Item onClick={() => deleteProject(data._id)}> */}
                        <Dropdown.Item
                          onClick={() => {
                            setProjectIdForDelete(data._id)
                            setIsModalVisible(true)
                          }}
                        >
                          <span className="pe-2">
                            <DeleteOutlined />
                          </span>
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => changeMode(data._id, !data.mode)}
                        >
                          <span
                            className={
                              data.mode ? 'deactivate-dot' : 'activate-dot'
                            }
                          />
                          {data.mode ? 'Deactivate' : 'Active'}
                        </Dropdown.Item>
                      </DropdownButton>
                    )}
                  </Col>
                </Row>
                <Link
                  to={{ pathname: `/project-detail/${data._id}` }}
                  className="card-link"
                >
                  <Row className="cursor-pointer mx-xl-3 my-xl-1 position-relative">
                    <Col
                      xs={8}
                      sm={10}
                      md={9}
                      lg={8}
                      xl={7}
                      className="justify-content-xs-end"
                    >
                      <img
                        src={
                          data.companys
                            ? data.companys[0].logo
                            : data.company
                            ? data.company.logo
                            : ''
                        }
                        alt="Not Found"
                        className="project-logo"
                      />
                      <div className="card-chart-wrapper">
                        <span>{Math.round(data.completedSurveyCount)}%</span>
                        <div className="card-chart">
                          <Doughnut
                            // options={option}
                            data={chartFn(
                              Math.round(data.completedSurveyCount),
                              Math.round(data.notCompletedSurveyCount),
                            )}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs={14}
                      sm={14}
                      md={15}
                      lg={16}
                      xl={12}
                      className="mt-2"
                    >
                      <span
                        style={{
                          textDecoration: data.mode ? 'none' : 'line-through',
                        }}
                      >
                        <b>{data.name}</b>
                      </span>
                      <Row justify="start">
                        <Col xs={16} sm={24} md={12} lg={16} xl={16}>
                          <p>
                            <img src={listImg} alt="users" />
                            &nbsp;&nbsp;
                            <span className="mt-1">{data.projectID}</span>
                            {/* &nbsp;&nbsp;PR{String(index + 1).padStart(5, '0')} */}
                          </p>
                        </Col>
                        <Col xs={8} sm={24} md={12} lg={8} xl={8}>
                          {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
                            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
                            <p>
                              <img
                                src={userCountImg}
                                alt="users"
                                className="img-fluid"
                              />
                              &nbsp;&nbsp;{' '}
                              {/* {data.employees ? data.employees.length : 0} */}
                              {data.totalEmployee + data.totalParticipant}
                              {/* set here number of users */}
                            </p>
                          )}

                          {readCookie(ROLE_KEYWORD) === EMPLOYEE_ROLE && (
                            <p>
                              <img
                                src={userCountImg}
                                alt="users"
                                className="img-fluid"
                              />
                              &nbsp;&nbsp;{' '}
                              {/* {data.employees ? data.employees.length : 0} */}
                              {data.participant.length}
                              {/* set here number of users */}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <div
                      className={
                        data.mode
                          ? 'live-status-circle'
                          : 'live-deactiveuser-circle'
                      }
                    />
                  </Row>
                </Link>
              </div>
            </Col>
          ))}
      </Row>
      {/* delete project modal  */}
      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        width={400}
        closable={false}
      >
        <div className="text-center">
          <img src={PopupConfirm} alt="Confirm" />
          <p className="mt-3 mb-3 fw-bold">
            Are you sure you want to remove Project ?
          </p>
          <div className="d-flex w-100 justify-content-center">
            <Button
              className="bg-theme-save-framwork-popup bg-transparent"
              onClick={() => {
                setIsModalVisible(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-theme-save-framwork-popup ms-2"
              onClick={() => {
                setIsModalVisible(false)
                deleteProject(ProjectIdForDelete)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
