/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react'
import './chart.css'
import { PieChart, Pie, Cell } from 'recharts'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Select } from 'antd'
import { useParams } from 'react-router-dom'
import { getAllRaters, RatersChartApi } from '../../../../../api/API'

const { Option } = Select

const RaterChart = (): JSX.Element => {
  const params = useParams<any>()
  const [raterlist, setRaterList] = useState<any>([])
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(100)
  const { id } = params // get project id from url
  // const [optionId, setOptionId] = useState('61b72a107904277afc7bdccd')
  const data = [
    {
      name: 'Not Completed Survey',
      survey: 100 - completeSurvey,
      color: '#FED68B',
    },
    {
      name: 'Completed Survey',
      survey: completeSurvey === null ? setCompleteSurvey(0) : completeSurvey,
      color: '#FCB737',
    },
  ]
  // get Raters Chart Data
  const getRaterChartData = (raterscategory: any): any => {
    const obj = {
      raterscategory,
    }
    RatersChartApi(id, obj).then((res: any): any => {
      // setChartData(res.data.data)
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }
  useEffect(() => {
    getAllRaters(params.id).then((res: any) => {
      setRaterList(res.data.data)
      getRaterChartData(res.data.data[0]._id)
    })
  }, [])

  return (
    <div className="mt-xl-5">
      <div className="p-2">
        <div className="text-center ">
          <h5>Rater Participation</h5>
          <Select
            placeholder="Raters Category"
            className="w-50"
            onChange={(e) => getRaterChartData(e)}
          >
            {raterlist.length > 0 &&
              raterlist.map((category: any) =>
                // eslint-disable-next-line eqeqeq
                category.title != 'Self' ? (
                  <Option key={category._id} value={category._id}>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          marginLeft: '10px',
                          backgroundColor: category.color,
                        }}
                      />
                      <div className="ms-2">{category.title}</div>
                    </div>
                  </Option>
                ) : null,
              )}
          </Select>
        </div>
        <div className="statsbody d-flex justify-content-around mt-0">
          <div className="pie">
            <PieChart width={400} height={400} className="">
              <Pie
                data={data}
                dataKey="survey"
                outerRadius={150}
                innerRadius={100}
                fill="#8884d8"
              >
                {data.map((entry, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
            <p className="percent-r">{completeSurvey}%</p>
          </div>
        </div>
        <div className="d-flex justify-content-around">
          {data.map((entry: any, index: number) => (
            <div className="d-flex" key={entry.name}>
              <div
                className="dot1 me-3"
                style={{ backgroundColor: entry.color }}
              />
              <p>{entry.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RaterChart
