export default class Route {
  // static baseURL = 'https://apidev.growmetrix.com'
  static baseURL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000'
      : 'https://apidev.growmetrix.com'

  static login = `${this.baseURL}/user/login`

  static logout = `${this.baseURL}/user/logout`

  static forgetPassword = `${this.baseURL}/user/forgot-password`

  static resetPassword = `${this.baseURL}/user/reset-password`

  // get current employee detail
  static currentEmployeeDetail = `${this.baseURL}/user/get-current-employee-detail`

  // DashBoard
  static dasboardHeader = `${this.baseURL}/dashboard/dashboard-counter`

  // Recent project
  static recentProject = `${this.baseURL}/dashboard/recent-project`

  // Company
  static createCompany = `${this.baseURL}/company`

  static companyDelete = `${this.baseURL}/company`

  static company = `${this.baseURL}/company` // for get single company

  static changeCompanyMode = `${this.baseURL}/company/change-mode`

  static filterCompany = `${this.baseURL}/company/filter-company`

  static getCompanyList = `${this.baseURL}/company/get-all`

  static companyList = `${this.baseURL}/company`

  // Employee
  static getEmployee = `${this.baseURL}/employee`

  static getEmployeeDetail = `${this.baseURL}/employee`

  static updateEmployeeDetail = `${this.baseURL}/employee`

  static filterEmployee = `${this.baseURL}/employee/filter-employee`

  static changeEmployeeStatus = `${this.baseURL}/employee/change-mode`

  static getCompanysEmployees = `${this.baseURL}/employee/get-companys-employee`

  static filterCompanysEmployees = `${this.baseURL}/employee/filter-companys-employee`

  static filterProjectEmployees = `${this.baseURL}/employee/filter-project-employee`

  // Employee Chart
  static getEmployeeChartDetail = `${this.baseURL}/project/calculate-employee-survey-status`

  // Employee Chart
  static getRatersChartDetail = `${this.baseURL}/project/calculate-participant-survey-status`

  // Participant
  static addParticipant = `${this.baseURL}/participant`

  static employee = `${this.baseURL}/employee`

  // Project
  static addProject = `${this.baseURL}/project`

  static getAllProject = `${this.baseURL}/project/get-all`

  static getProjectDetail = `${this.baseURL}/project`

  static updateProjectDetail = `${this.baseURL}/project`

  static removeProject = `${this.baseURL}/project`

  static changeProjectMode = `${this.baseURL}/project/change-mode`

  static filterProject = `${this.baseURL}/project/filter-projects`

  static getProjectsEmployee = `${this.baseURL}/project/project-employee`

  static getMyProjects = `${this.baseURL}/project/get-my-projects`

  static filterMyProjects = `${this.baseURL}/project/filter-my-projects`

  static getMyProjectsLists = `${this.baseURL}/project/get-companys-projects-list`

  static getMyProjectEmployeeLists = `${this.baseURL}/project/get-projects-employee-list`

  // question (survey)

  static addQuestion = `${this.baseURL}/question`

  static getQuestions = `${this.baseURL}/question`

  static publishForm = `${this.baseURL}/question/publish-form`

  static moveToTrash = `${this.baseURL}/question/move-to-trash`

  static restoreFromTrash = `${this.baseURL}/question/restore-from-trash`

  static getSurvey = `${this.baseURL}/survey/get-survey`

  static addAnswers = `${this.baseURL}/survey/addanswers`

  static updateSurveyReplayStatus = `${this.baseURL}/survey/update-survey-replay-status`

  // Framework
  static getAllFocusArea = `${this.baseURL}/focusarea/all`

  static getFocusArea = `${this.baseURL}/focusarea`

  static addFocusArea = `${this.baseURL}/focusarea`

  static updateFocusArea = `${this.baseURL}/focusarea`

  static deleteFocusArea = `${this.baseURL}/focusarea`

  static getConstructs = `${this.baseURL}/focusarea/get-constructs`

  static addConstructs = `${this.baseURL}/focusarea/add-constructs`

  static updateConstructs = `${this.baseURL}/focusarea/update-constructs`

  static getAllRaters = `${this.baseURL}/raters/get-all`

  static getCompanyRaters = `${this.baseURL}/raters/get-companys-raters`

  static addRaters = `${this.baseURL}/raters/`

  static deleteRaters = `${this.baseURL}/raters/`

  static getRater = `${this.baseURL}/raters/`

  static updateRater = `${this.baseURL}/raters/`

  // Add User Inquiry
  static addInquiry = `${this.baseURL}/inquiry`

  // Report Page
  static myReportProjectTable = `${this.baseURL}/reports/get-companys-project-report-list`

  static getEmployeeProjectReportPDF = `${this.baseURL}/reports/get-project-report-pdf-data`

  static getProjectReportPDF = `${this.baseURL}/reports/get-project-report-pdf-data-project`

  // list of Report menu table data
  static getCompanyReportTableData = `${this.baseURL}/reports/get-company-report-list`

  // list of Employee table for Report page
  static getEmployeeReportTableData = `${this.baseURL}/reports/get-my-project-report-list`

  static filterEmployeeReportTableData = `${this.baseURL}/reports/filter-my-project-report-list`

  static filterCompanyReportTableData = `${this.baseURL}/reports/filter-companys-project-report-list`

  // list of Report menu table data
  static getNestedProjectReportTableData = `${this.baseURL}/reports/get-company-project-report-list`

  // filter report company
  static filterReportCompanty = `${this.baseURL}/reports/filter-report-company`

  // Distribution page
  static getAllDistribution = `${this.baseURL}/distributions/get-all`

  // Distribution page
  static closeDistribution = `${this.baseURL}/distributions/close-distribution`

  // Distribution page cascader data
  static getDistributionCacader = `${this.baseURL}/project/get-list-of-employee-and-participant`

  // add Distribution data
  static addDistribution = `${this.baseURL}/distributions`

  // get single Distribution data
  static getSingleDistribution = `${this.baseURL}/distributions`

  // update Distribution data
  static updateDistribution = `${this.baseURL}/distributions`

  // get employees data for data analysis
  static getEmployeesData = `${this.baseURL}/question/dataandanalysis`

  // Delete Emplyoee
  static deleteEmployees = `${this.baseURL}/employee`

  // fiter
  static getDataAndAnalysysFilter = `${this.baseURL}/survey/get-data-and-analysis-filter`

  // get employees data for data analysis only if login user is employee
  static getEmployeeUserTaleData = `${this.baseURL}/question/get-employee-data-and-analysis`

  // get last send Remainder data for data analysis
  static getLastSharedRemainderData = `${this.baseURL}/distributions/get-last-shared-reminder`

  // send Remainder data from data analysis
  static sendRemainderData = `${this.baseURL}/distributions/send-reminder`

  // distributionfilter
  static distributionsFilter = `${this.baseURL}/distributions/distributions-filter`

  // get Raters & Participant list for data analysis Response Modal
  static getRatersAndParticipantsData = `${this.baseURL}/survey/get-respondant-raters`

  //  get All Projects List
  static getAllProjectList = `${this.baseURL}/project/get-project-list`

  //  change password from preferences menu
  static changeUserPassword = `${this.baseURL}/user/change-password`

  // project detail-> user -> user (remove paricipant api)
  static removeParticipant = `${this.baseURL}/participant`

  // get single participation data
  static getSingleParticipant = `${this.baseURL}/participant`

  // update participation data
  static updateParticipation = `${this.baseURL}/participant`

  // get single participation chart data
  static getParticipantSurveyStatus = `${this.baseURL}/employee/overall-participant-survey-status`

  // get company detail -> Participant -> current employee chart data
  static getSingleEmployeeSurveyStatus = `${this.baseURL}/employee/overall-single-employee-survey-status`

  // get single participation chart data
  static getRatersSurveyStatus = `${this.baseURL}/employee/overall-raters-survey-status`

  // get all participants
  static getAllParticipation = `${this.baseURL}/participant/get-my-participant`

  // get company detail -> detail tab -> employee chart data
  static getEmployeeSurveyStatus = `${this.baseURL}/company/calculate-employee-survey-status`

  // get details of current company login user
  static getCurrentCompany = `${this.baseURL}/user/get-current-company-detail`

  // get details of current Employee login user
  // static getCurrentEmployee = `${this.baseURL}/user/get-current-employee-detail`

  // Template
  static createTemplate = `${this.baseURL}/template/create-template`

  // get list of Templates
  static getTemplateTableData = `${this.baseURL}/template/getall-template`

  // get all focus afre Templates
  static getAllFocusAreaTemplate = `${this.baseURL}/template/get-all-focus-area-template`

  // get list of Templates
  static createTemplateFocusArea = `${this.baseURL}/template/create-focus-area-template`

  static importTemplateFocusArea = `${this.baseURL}/template/import-focus-area-template`

  static getListOfAllTemplate = `${this.baseURL}/template/get-list-of-all-template`

  static importMainTemplate = `${this.baseURL}/template/import-main-template`

  static filterTemplate = `${this.baseURL}/template/filter-template`

  static deleteTemplate = `${this.baseURL}/template/delete-template`

  static ExportDataToCSV = `${this.baseURL}/reports/get-employee-report-csv-data`

  static FilterEmployeeDataAndAnalysis = `${this.baseURL}/survey/filter-employee-data-and-analysis`

  static addChartImage = `${this.baseURL}/project/chart-image-upload`

  static removeChartImage = `${this.baseURL}/project/chart-image-remove`
}
