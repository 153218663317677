/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
  Button,
} from 'antd'
import CameraIcon from '../../../assets/images/Icon feather-camera.svg'
import UserIcon from '../../../assets/images/Icon awesome-user-circle.svg'
import { updateEmployeeDetail, currentEmployeeDetail } from '../../../api/API'
import { readCookie } from '../../../api/CookieScript'
import {
  SUPER_ADMIN_ROLE,
  EMPLOYEE_ROLE,
  COMPANY_ROLE,
  ROLE_KEYWORD,
} from '../../../constants/index'

interface Props {
  employeeId: any
  employeeData: any
  setIsDrawerVisible: any
}

export const EditEmployeeProfile: React.FC<Props> = ({
  employeeId,
  employeeData,
  setIsDrawerVisible,
}): JSX.Element => {
  const [logo, setlogo] = useState<any>(employeeData.image)
  const [priviewImg, setPriviewImg] = useState<any>(employeeData.image)
  const [name, setName] = useState(employeeData.name)
  const [firstName, setFirstName] = useState(employeeData.first_name)
  const [lastName, setLastName] = useState(employeeData.last_name)
  const [email, setEmail] = useState(employeeData.email)
  const [phone, setPhone] = useState(employeeData.phone)

  // Edit Employee Section Code
  const handleSubmit = (): void => {
    const formData = new FormData()
    formData.append('image', logo)
    formData.append('name', name)
    formData.append('first_name', firstName)
    formData.append('last_name', lastName)
    // formData.append('email', email)
    formData.append('phone', phone)
    // eslint-disable-next-line no-underscore-dangle
    updateEmployeeDetail(formData, employeeId).then((res: any) => {
      const result = res.data
      setIsDrawerVisible(false)
      notification.success({
        message: result.message,
        description: '',
      })
      window.location.reload()
      const myTimeout = setTimeout(currentEmployeeDetail, 2000)
      console.log(myTimeout)
    })
  }

  const imagePreview = (e: any): void => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setPriviewImg(objectUrl)
    setlogo(e.target.files[0])
  }
  return (
    <>
      <div className="p-3 edit-employee-drawer">
        <Form onFinish={handleSubmit}>
          <Row justify="space-between" className="mt-3" gutter={[8, 8]}>
            <div className="d-flex justify-content-between align-items-center">
              {/* <p>My Profile</p> */}
              <div className="position-relative">
                <img
                  className="profile-pic"
                  src={priviewImg || UserIcon}
                  alt="profile-pic"
                />
                <label className="filebutton">
                  <img
                    src={CameraIcon}
                    alt="Camrea"
                    className="camera-icon mt-1"
                  />
                  <span>
                    <input
                      type="file"
                      id="myfile"
                      // disabled={isReadOnly}
                      onChange={(e) => imagePreview(e)}
                    />
                  </span>
                </label>
              </div>
              <p className="w-50 bg-EDECEC p-2 profile-pic-rule">
                please make sure the profile pic file must be less then 1mb in a
                dimentation of 150 x 150
              </p>
            </div>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label>
                User Name <span className="text-danger">*</span>
              </label>
              <Form.Item
                // id="name"
                name="name"
                initialValue={name}
                rules={[
                  {
                    required: true,
                    message: 'The name is required.',
                  },
                  // {
                  //   pattern: /^[a-zA-Z]$/,
                  //   message: 'Name can only include letters and numbers.',
                  // },
                ]}
              >
                <Input
                  // id="name"
                  // placeholder="Enter Name"
                  // className="form-control popup-sidebar-form-input"
                  // defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                  // key={name}
                />
              </Form.Item>
            </Col>
            <Col xs={23} sm={24} md={12} lg={12} xl={12}>
              <label>
                First Name <span className="text-danger">*</span>
              </label>
              <Form.Item
                name="firstName"
                initialValue={firstName}
                rules={[{ required: true, message: 'Please Enter First Name' }]}
              >
                <Input
                  placeholder="Clark"
                  className="popup-sidebar-form-input"
                  // defaultValue={firstName}
                  // key={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={23} sm={24} md={12} lg={12} xl={12}>
              <label>
                Last Name <span className="text-danger">*</span>
              </label>
              <Form.Item
                name="lastName"
                initialValue={lastName}
                rules={[{ required: true, message: 'Please Enter Last Name' }]}
              >
                <Input
                  placeholder="Clark"
                  className="popup-sidebar-form-input"
                  // defaultValue={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                  //   disabled={isReadOnly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label>Email</label>
              <Form.Item>
                <p className="email-readonly-input form-control">{email}</p>
                {/* <Input
                  className="popup-sidebar-form-input"
                  value={email}
                  readOnly
                /> */}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label>Phone</label>
              <Form.Item
                name="phone2"
                initialValue={phone}
                rules={[
                  { required: false, message: 'Please Enter Phone Number' },
                  {
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Please Enter Valid Phone Number',
                  },
                ]}
              >
                <input
                  // value={phone}
                  // defaultValue={phone}
                  className="popup-sidebar-form-input w-100"
                  onChange={(e: any) => setPhone(e.target.value)}
                  maxLength={15}
                  pattern="[0-9]{1}[0-9]{9}"
                  // min={10}
                  // max={15}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="d-flex justify-content-end pt-4 pe-3">
            <Button
              onClick={() => setIsDrawerVisible(false)}
              className="float-right me-2 cancel-btn"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="float-right save-btn"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
      {/* {readCookie(ROLE_KEYWORD) === COMPANY_ROLE && (
        <div className="bg-EDECEC p-2 m-3 user-popup-note">
          <b>Please Note: </b>
          <p>
            User will receive an invite email to join to the particular company
          </p>
        </div>
      )} */}
    </>
  )
}
