/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import './style/user-survey.scss'
import { Button, Progress, Spin } from 'antd'
import { Form } from 'react-bootstrap'
import { getSurvey, addAnswer, updateSurveyReplayStatus } from '../../api/API'
import GrowMetrixLogo from '../../assets/images/growmatrix-logo.svg'
import ONTImage from '../../assets/images/Original on Transparent1.png'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { v4: uuidv4 } = require('uuid')

export const UserSurvey: React.FC = (): JSX.Element => {
  const currentUrl = window.location.href
  const splitedUrl = currentUrl.split('/')
  const [projectDetail, setProjectDetail] = useState<any>()
  const [tmpState, setTmpState] = useState(false)
  const [allQuestions, setAllQuestions] = useState<any>([])
  const [answers, setAnswers] = useState<any>([])
  const [formProgress, setFormProgress] = useState(0)
  const [cuntProgress, setcuntProgress] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState<any>()
  const [surveyID, setSurveyID] = useState('')
  const [userID, setUserID] = useState('')

  const [count, setCount] = useState(0)

  const [answeredQuestionList, setAnsweredQuestionList] = useState<any>([])
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState('')
  const [Progresstartus, setProgresstartus] = useState(false)

  const [requiredQuestions, setRequiredQuestions] = useState<any>([])

  const [loading, setLoading] = useState(false)
  const [showProgressbar, setShowProgressbar] = useState(false)
  const FormProgressmain = Math.floor((count * 100) / allQuestions.length)
  useEffect(() => {
    setLoading(true)
    const surveyId = splitedUrl[splitedUrl.length - 1]
    const userId = splitedUrl[splitedUrl.length - 2]
    setSurveyID(surveyId)
    setUserID(userId)
    const data = {
      userId,
    }
    getSurvey(surveyId, data).then((res: any) => {
      const result = res.data.data
      // setAllQuestions(result)
      setTotalQuestions(result.questionList.length)
      setcuntProgress(result.questionList.length)
      setProjectDetail(result.companyDetail)
      if (
        new Date(result.companyDetail.start_date) < new Date() &&
        new Date(result.companyDetail.end_date) > new Date() &&
        !result.isSurveyCompleted &&
        result.questionList.length !== 0
      ) {
        generateForm(result.questionList)
        setAnswers(result.avaliableAnswers)
        const availAnswer = result.avaliableAnswers
        const answerdqList = answeredQuestionList
        for (let index = 0; index < availAnswer.length; index++) {
          answerdqList.push(availAnswer[index].question_id)
        }
        setAnsweredQuestionList(answerdqList)
      } else if (result.isSurveyCompleted) {
        setMessage(`You have been completed the survey successfully.`)
      } else if (new Date(result.companyDetail.end_date) < new Date()) {
        setMessage(`Survey is expired`)
      } else if (result.questionList.length === 0) {
        setMessage(`This Survey is not for you.`)
      } else {
        setMessage(
          `Survey will be start on this date ${new Date(
            result.companyDetail.start_date,
          )}`,
        )
      }
      setLoading(false)
    })
  }, [])
  const generateForm = (questionList: any): void => {
    const combineArray: any[] = []
    let sliceContainer: any[] = []
    const tempRequiredArray: any = []
    // eslint-disable-next-line array-callback-return
    questionList.map((b: any, i: any) => {
      b.data.map((question: any, index: number) => {
        return combineArray.push(question)
      })
      if (i !== questionList.length - 1) {
        combineArray.push({
          questionCounter: uuidv4(),
          questionType: 'page-break',
          options: [],
          keyfocusArea: null,
          construct: null,
          allowMultiple: false,
        })
      }
    })
    const finalArray: any[] = []
    combineArray.forEach((element: any, index: number) => {
      if (element.questionType === 'page-break') {
        if (sliceContainer.length > 0) {
          finalArray.push(sliceContainer)
          sliceContainer = []
        }
      } else {
        sliceContainer.push(element)
      }
      if (index === combineArray.length - 1) {
        if (sliceContainer.length > 0) {
          finalArray.push(sliceContainer)
          sliceContainer = []
        }
      }

      if (
        // element.questionType !== 'free-text' ||
        // element.questionType !== 'html-editor' ||
        element.questionType !== 'page-break'
      ) {
        tempRequiredArray.push(element)
      }
    })
    setRequiredQuestions(tempRequiredArray)
    setAllQuestions(finalArray)
    setTmpState(!tmpState)
    setLoading(false)
  }

  const incrementCounter = (): void => {
    setShowProgressbar(true)
    let tmp = count
    if (tmp < 1) {
      tmp++
      setCount(tmp)
    }

    if (tmp >= 1) {
      // checkIfAllAnswerd()
      setIsError(false)
      if (!checkIfAllAnswerd()) {
        const countPersentage = Math.floor(
          (100 * answeredQuestionList.length) / totalQuestions,
        )
        setFormProgress(countPersentage)
        // || tmp === allQuestions.length - 1
        setcuntProgress(cuntProgress - 1)
        if (
          count + 1 !==
          allQuestions.filter(
            (ele: any) =>
              // ele.questionType !== 'free-text' &&
              ele.questionType !== 'html-editor' && ele.isMandatory !== false,
          ).length
        ) {
          setCount(count + 1)

          saveResponse()
        } else {
          const apiData = {
            user_id: userID,
            survey_id: surveyID,
          }
          saveResponse()
          updateSurveyReplayStatus(apiData)
            .then((res: any) => {
              // setFormProgressmain(100)
              setProgresstartus(true)
              setMessage(`Thanks for your feedback`)
            })
            .catch((err: any) => {
              console.log('notsved')
            })
          // setMessage(`Thanks for your feedback`)
        }
      } else {
        setIsError(true)
      }
    }
  }

  const checkIfAllAnswerd = (): boolean => {
    let havingAnyError = false
    if (count > 0) {
      // eslint-disable-next-line array-callback-return
      allQuestions[count].map((question: any, index: any): void => {
        if (
          // question.questionType !== 'free-text' &&
          question.questionType !== 'html-editor' &&
          question.questionType !== 'page-break' &&
          question.isMandatory !== false
        ) {
          if (!answeredQuestionList.includes(question.questionCounter)) {
            // setIsError(true)
            havingAnyError = true
          } else {
            // setIsError(false)
            havingAnyError = false
          }

          // if(answeredQuestionList.length === 0) {
          //   havingAnyError = false
          // }
        }
      })
    }

    if (havingAnyError) {
      return true
    }

    return false
  }

  const decrementCounter = (): void => {
    setcuntProgress(cuntProgress + 1)
    if (count >= 1) {
      setCount(count - 1)
      // const countPersentage = Math.floor(
      //   (100 * (count - 1)) / allQuestions.length,
      // )
      const countPersentage = Math.floor(
        (100 * answeredQuestionList.length) / totalQuestions,
      )
      setFormProgress(countPersentage)
    } else {
      setShowProgressbar(false)
    }
  }

  const addAnswers = (
    questionId: any,
    answer: any,
    type: string,
    isChecked?: boolean,
  ): void => {
    if (answers.length === 0) {
      const data = answers
      const tmp = {
        question_id: questionId,
        answer: [answer],
      }
      data.push(tmp)
      setAnswers(data)
    } else {
      const data = answers
      if (type === 'checkbox') {
        let isNew = true
        for (let i = 0; i < data.length; i++) {
          if (data[i].question_id === questionId) {
            if (isChecked) {
              data[i].answer.push(answer)
            } else {
              const ans = data[i].answer.filter((d: any) => d !== answer)
              data[i].answer = ans
            }
            isNew = false
          }
        }
        if (isNew) {
          const tmp = {
            question_id: questionId,
            answer: [answer],
          }
          data.push(tmp)
        }
        setAnswers(data)
      } else if (type === 'matrix-table') {
        const expload = answer.split('_')
        let isNew = true // question
        for (let i = 0; i < data.length; i++) {
          if (data[i].question_id === questionId) {
            const selectedoptions = data[i].answer
            let isNewOption = false // options
            for (let j = 0; j < selectedoptions.length; j++) {
              const texplode = selectedoptions[j].split('_')
              if (texplode[1] === expload[1]) {
                selectedoptions[j] = answer
                isNewOption = false
                break
              } else {
                isNewOption = true
              }
            }
            if (isNewOption) {
              selectedoptions.push(answer)
            }
            data[i].answer = selectedoptions
            isNew = false
          } else {
            isNew = true
          }
        }
        if (isNew) {
          const tmp = {
            question_id: questionId,
            answer: [answer],
          }
          data.push(tmp)
        }
        setAnswers(data)
      } else {
        let isNew = true // options
        for (let i = 0; i < data.length; i++) {
          if (data[i].question_id === questionId) {
            data[i].answer = answer
            isNew = false
          }
        }
        if (isNew) {
          const data1 = answers
          const tmp = {
            question_id: questionId,
            answer,
          }
          data1.push(tmp)
          setAnswers(data1)
        }
        setAnswers(data)
      }
    }

    const tmp = answeredQuestionList
    if (!answeredQuestionList.includes(questionId)) {
      tmp.push(questionId)
      setAnsweredQuestionList(tmp)
    }
  }

  const saveResponse = (): void => {
    setLoading(true)
    const countPersentage = Math.floor(
      (100 * answers.length) / allQuestions.length,
    )
    const apiData = {
      answers,
      user_id: userID,
      survey_id: surveyID,
      percent: countPersentage,
    }
    addAnswer(apiData)
      .then((res: any) => {
        const { data } = res.data
        setAnswers(data)
        setLoading(false)
      })
      .catch((err: any) => {
        console.log('notsved')
      })
  }

  const getDropDownAnsewer = (questionCounter: any): string => {
    const answerStr = answers.map((answer: any) =>
      answer.question_id === questionCounter ? answer.answer[0] : undefined,
    )
    const color = answerStr.toString()
    const colorf = color.replaceAll(',', '')
    return colorf
  }

  const getCheckboxAnsewer = (questionCounter: any, value: string): any => {
    let isAvail = false
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].question_id === questionCounter) {
        if (answers[index].answer.includes(value)) {
          isAvail = true
          break
        } else {
          isAvail = false
        }
      } else {
        isAvail = false
      }
    }
    return isAvail
  }

  // this is working
  // const FormProgress = 100 - Math.floor((cuntProgress * 100) / totalQuestions)
  // console.log('message', message)
  // setFormProgress()

  /** ************** Scroll  ******** */
  // const [visible, setVisible] = useState(false)

  // // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300){
  //     setVisible(true)
  //   }
  //   else if (scrolled <= 300){
  //     setVisible(false)
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
       in place of 'smooth' */
    })
  }

  // window.addEventListener('scroll', toggleVisible);

  return (
    <Spin spinning={loading}>
      <div className="user-survey-form">
        <div className="form-container mt-3 border border-1 p-3">
          <div className="d-flex justify-content-between mb-5">
            {projectDetail && projectDetail.manage_branding && (
              <>
                <img src={GrowMetrixLogo} alt="" style={{ width: '30%' }} />
                <img src={ONTImage} alt="" style={{ width: '23%' }} />
              </>
            )}
          </div>

          <div className="text-center my-5">
            {projectDetail && projectDetail.manage_branding ? (
              <></>
            ) : (
              <>
                <img
                  src={
                    projectDetail ? projectDetail.company.logo : GrowMetrixLogo
                  }
                  alt=""
                  className="company-logo-2"
                />
              </>
            )}

            {message !== '' && (
              <p style={{ textAlign: 'center', marginTop: '75px' }}>
                {/* <span>
                  <h1>Thank you</h1>
                </span> */}
                <br />
                <h3>{message}</h3>
              </p>
            )}
          </div>

          {allQuestions.length > 0 &&
            message === '' &&
            allQuestions[count].map((question: any, index: any) => (
              <div key={question.questionCounter} className="mt-1 mb-1">
                {question.questionType === 'html-editor' && (
                  <div
                    dangerouslySetInnerHTML={{ __html: question.htmlData }}
                  />
                )}
                {question.questionType === 'free-text' && (
                  <Form.Group className="mb-4 custom-buttons">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                      {isError &&
                        !answeredQuestionList.includes(
                          question.questionCounter,
                        ) &&
                        question.isMandatory && (
                          <p className="text-danger">
                            Please answer this question
                          </p>
                        )}
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      placeholder="Enter answer"
                      onChange={(e) =>
                        addAnswers(
                          question.questionCounter,
                          e.target.value,
                          'free-text',
                        )
                      }
                    />
                  </Form.Group>
                )}
                {question.questionType === 'multiple-choice' && (
                  <Form.Group className="custom-buttons">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                      {isError &&
                        !answeredQuestionList.includes(
                          question.questionCounter,
                        ) &&
                        question.isMandatory && (
                          <p className="text-danger">
                            Please answer this question
                          </p>
                        )}
                    </Form.Label>
                    <div className="d-flex flex-wrap mb-4">
                      {question.options.map((option: any, i: any) => {
                        return (
                          <Form.Check
                            key={option.value}
                            type={question.allowMultiple ? 'checkbox' : 'radio'}
                            label={option.text}
                            name={`multiplechoice_${question.questionCounter}`}
                            defaultChecked={getCheckboxAnsewer(
                              question.questionCounter,
                              `${option.value}`,
                            )}
                            value={option.value}
                            onChange={(e) =>
                              addAnswers(
                                question.questionCounter,
                                e.target.value,
                                'multiple-choice',
                              )
                            }
                          />
                        )
                      })}
                    </div>
                  </Form.Group>
                )}
                {question.questionType === 'checkbox' && (
                  <Form.Group className="custom-buttons">
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                      {isError &&
                        !answeredQuestionList.includes(
                          question.questionCounter,
                        ) &&
                        question.isMandatory && (
                          <p className="text-danger">
                            Please answer this question
                          </p>
                        )}
                    </Form.Label>
                    <div className="d-flex flex-wrap mb-4">
                      {question.options.map((option: any, i: any) => {
                        return (
                          <Form.Check
                            key={option.value}
                            type={question.allowMultiple ? 'checkbox' : 'radio'}
                            label={option.text}
                            name="abnc"
                            value={option.value}
                            defaultChecked={getCheckboxAnsewer(
                              question.questionCounter,
                              `${option.value}`,
                            )}
                            data-index1="asasdd"
                            onChange={(e) =>
                              addAnswers(
                                question.questionCounter,
                                e.target.value,
                                'checkbox',
                                e.target.checked,
                              )
                            }
                          />
                        )
                      })}
                    </div>
                  </Form.Group>
                )}
                {question.questionType === 'dropdown' && (
                  <Form.Group>
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                      {isError &&
                        !answeredQuestionList.includes(
                          question.questionCounter,
                        ) &&
                        question.isMandatory && (
                          <p className="text-danger">
                            Please answer this question
                          </p>
                        )}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      multiple={false}
                      onChange={(e: any) =>
                        addAnswers(
                          question.questionCounter,
                          e.target.value,
                          'dropdown',
                        )
                      }
                      defaultValue={getDropDownAnsewer(
                        question.questionCounter,
                      )}
                    >
                      <option>Open this select menu</option>
                      {question.options.map((option: any, i: any) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.text}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                )}
                {question.questionType === 'matrix-table' && (
                  <Form.Group>
                    <Form.Label>
                      <b>
                        {question.questionTitle}{' '}
                        {question.isMandatory ? (
                          <span className="text-danger">*</span>
                        ) : (
                          ''
                        )}
                      </b>
                      {isError &&
                        !answeredQuestionList.includes(
                          question.questionCounter,
                        ) &&
                        question.isMandatory && (
                          <p className="text-danger">
                            Please answer this question
                          </p>
                        )}
                    </Form.Label>
                    <table className="mt-2 mb-2 matrix-table w-100">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          {question.metrixtableScaleArray.map(
                            (scalePoint: any, i: any) => {
                              return (
                                <th key={i}>
                                  <p className="mb-0">{scalePoint}</p>
                                </th>
                              )
                            },
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {question.options.map((option: any, i: any) => {
                          return (
                            <tr key={option.value} className="h-50px">
                              <td style={{ width: '20%' }}>
                                <p className="mb-0">{option.text}</p>
                              </td>
                              {question.metrixtableScaleArray.map(
                                (_: any, indexofspa: any) => (
                                  <td key={indexofspa}>
                                    <Form.Check
                                      type={
                                        question.allowMultiple
                                          ? 'checkbox'
                                          : 'radio'
                                      }
                                      value={`${indexofspa}_${i}`}
                                      name={`matrix_table_scale_point_radio_${i}_${question.blockNo}`}
                                      defaultChecked={getCheckboxAnsewer(
                                        question.questionCounter,
                                        `${indexofspa}_${i}`,
                                      )}
                                      onChange={(e) =>
                                        addAnswers(
                                          question.questionCounter,
                                          e.target.value,
                                          'matrix-table',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                  </td>
                                ),
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Form.Group>
                )}
              </div>
            ))}
          {message === '' && allQuestions.length > 0 && (
            <div className="d-flex justify-content-between mt-5 mb-5">
              <Button
                className="comman-button btn-theme rounded border-0"
                onClick={() => {
                  decrementCounter()
                  scrollToTop()
                }}
                style={{ visibility: count > 0 ? 'visible' : 'hidden' }}
              >
                Back
              </Button>
              <Button
                className="comman-button btn-theme rounded border-0"
                onClick={() => {
                  // checkIfAllAnswerd()
                  incrementCounter()
                  scrollToTop()
                }}
              >
                Next
              </Button>
            </div>
          )}
          {showProgressbar && (
            <div className="mt-5 mb-5 d-flex">
              <Progress
                percent={!Progresstartus ? FormProgressmain : 100}
                strokeWidth={20}
                strokeColor="#008c7e"
                showInfo={false}
              />
              <span className="px-2">
                {!Progresstartus ? `${FormProgressmain}%` : '100%'}
              </span>
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
}
