import React, { useState, useEffect } from 'react'
import './style/header.scss'
import { Row, Col, Drawer } from 'antd'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { HomeOutlined, FolderAddOutlined } from '@ant-design/icons'
import RangePicker from '../InputElements/DatePicker'
import arrowDown from '../../assets/images/arrow_down.svg'
import arrowDownWhite from '../../assets/images/arrow-down-white.svg'
import buildingsgray from '../../assets/images/buildingsgray.svg'
import userIcon from '../../assets/images/Group 9264.svg'
import { AddCompany } from '../../views/company/components/addCompany'
import { AddProject } from '../../views/my-projects/components/addproject'
import DrawerFooter from '../Drawer/footer/drawerFooter'
import { readCookie } from '../../api/CookieScript'
import { SUPER_ADMIN_ROLE, COMPANY_ROLE, ROLE_KEYWORD } from '../../constants'
// import {
//   SUPER_ADMIN_ROLE,
//   EMPLOYEE_ROLE,
//   COMPANY_ROLE,
//   ROLE_KEYWORD,
//   TOKEN_KEYWORD,
//   USERNAME_KEYWORD,
// } from '../../constants'

export const Header = (): JSX.Element => {
  const [companydrawerVisible, setCompanyDrawerVisible] = useState(false)
  const [projectdrawerVisible, setProjectDrawerVisible] = useState(false)

  return (
    <>
      <Row className="pt-3 header">
        {/* <span className="header-Container"> */}
        <Col
          xs={{ span: 16, order: 1 }}
          sm={18}
          md={{ span: 8, order: 1 }}
          lg={{ span: 11, order: 1 }}
          xl={{ span: 14 }}
          xxl={{ span: 16, order: 1 }}
          className="header-left"
        >
          <span className="header-title">DASHBOARD</span>
          <span className="header-subtitle">Check your overall statistics</span>
        </Col>
        {/* <div className="header-right"> */}
        <Col
          xs={{ span: 17, order: 3 }}
          sm={12}
          md={{ span: 8, order: 2 }}
          lg={{ span: 7, order: 3 }}
          xl={{ span: 4 }}
          xxl={{ span: 3, order: 2 }}
          className="mt-xs-2 mt-xl-2"
        >
          {/* <RangePicker /> */}
        </Col>
        <Col
          xs={{ span: 6, order: 4 }}
          sm={12}
          md={{ span: 3, order: 3 }}
          lg={{ span: 3, order: 3 }}
          xl={{ span: 2 }}
          xxl={{ span: 2, order: 3 }}
        >
          {/* <Dropdown>
            <Dropdown.Toggle
              className="allDayMenu border-1 mt-xl-2"
              id="dropdown-basic"
            >
              All Day
              <img src={arrowDown} alt="arrow" className="arrow_down" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">All Day</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Last Quarter</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Current Year</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </Col>
        <Col
          xs={{ span: 8, order: 2 }}
          sm={12}
          md={{ span: 3, order: 4 }}
          lg={{ span: 3, order: 4 }}
          xl={{ span: 3 }}
          xxl={{ span: 2, order: 4 }}
        >
          <Dropdown>
            {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
              readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
              <Dropdown.Toggle
                className="create_new_btn dropmenu border-1 mt-xl-2"
                id="dropdown-basic"
              >
                Create New
                <img src={arrowDownWhite} alt="arrow" className="arrow_down" />
              </Dropdown.Toggle>
            )}
            <Dropdown.Menu>
              {readCookie('role') === SUPER_ADMIN_ROLE && (
                <Dropdown.Item onClick={() => setCompanyDrawerVisible(true)}>
                  <HomeOutlined className="me-2" />
                  Company
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => setProjectDrawerVisible(true)}>
                <FolderAddOutlined className="me-2" />
                Project
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        {/* </div> */}
        {/* </span> */}
      </Row>
      {/* company drawer */}
      <div className="drawer-wrapper">
        <Drawer
          onClose={() => {
            setCompanyDrawerVisible(false)
          }}
          visible={companydrawerVisible}
          title={
            <span>
              <img
                src={buildingsgray}
                alt="Add New Company"
                className="me-3 drawer-head-icon"
              />
              ADD NEW COMPANY
            </span>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <AddCompany setDrawerVisible={setCompanyDrawerVisible} />
        </Drawer>
        {/* project header */}
        <Drawer
          onClose={() => {
            setProjectDrawerVisible(false)
          }}
          visible={projectdrawerVisible}
          title={
            <>
              <img src={userIcon} className="drawer-head-icon" alt="user" />
              <span className="ms-2">ADD NEW PROJECT</span>
            </>
          }
          width={window.innerWidth > 550 ? 465 : '100%'}
          footer={<DrawerFooter />}
        >
          <AddProject setDrawerVisible={setProjectDrawerVisible} />
        </Drawer>
      </div>
      <hr className="header__hr" />
    </>
  )
}
