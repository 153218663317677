/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import './chart.css'
import { useState, useEffect } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { Select } from 'antd'
import { useParams } from 'react-router-dom'
import {
  getParticipantSurveyStatusApi,
  getSingleEmployeeSurveyStatusApi,
} from '../../../../../../../api/API'

const { Option } = Select
interface Props {
  participantList: any
  employeeId: any
}

export const EmployeeChart: React.FC<Props> = ({
  participantList,
  employeeId,
}): JSX.Element => {
  // const EmployeeChart = (): JSX.Element => {
  const [detail, setDetail] = useState({})
  const params = useParams<any>()
  const [empId, setEmpId] = useState(employeeId)
  const [completeSurvey, setCompleteSurvey] = useState(0)
  const [notCompleteSurvey, setNotCompleteSurvey] = useState(0)
  const data = [
    {
      name: 'Not Completed Survey',
      // survey: notCompleteSurvey,
      survey: Math.round(100 - completeSurvey),
      color: '#A6CEE3',
    },
    {
      name: 'Completed Survey',
      survey:
        completeSurvey === null
          ? setCompleteSurvey(0)
          : Math.round(completeSurvey),
      color: '#1F78B4',
    },
  ]
  // on page load pass participant id static all and get chart data
  useEffect(() => {
    getParticpantChartData('all')
  }, [])
  // function call on change and get chart data
  const getParticpantChartData = (patId: any): any => {
    const apidata = {
      employee_id: empId,
      participant_id: patId,
      project_id: params.id,
    }

    // getParticipantSurveyStatusApi(apidata).then((res: any) => {
    //     console.log("res",res);
    //   setCompleteSurvey(res.data.data.completedSurveyStatus)
    //   setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    // })
    const apiSingleEmployeeData = {
      employee_id: empId,
      participant_id: patId,
      project_id: params.id,
    }
    getSingleEmployeeSurveyStatusApi(apiSingleEmployeeData).then((res: any) => {
      setCompleteSurvey(res.data.data.completedSurveyStatus)
      setNotCompleteSurvey(res.data.data.notCompletedSurveyStatus)
    })
  }
  return (
    <>
      <h5>Employee Participation</h5>
      <div className="position-relative mt-1">
        {/* <Select
          defaultValue="all"
          style={{ width: '100%' }}
          onChange={(e) => getParticpantChartData(e)}
        >
          <Option value="all">All</Option>
          {participantList.map((user: any, index: any) => (
            <Option key={index} value={user._id}>
              {user.name}
            </Option>
          ))}
        </Select> */}
      </div>
      <div className="statsbody d-flex justify-content-around mt-0">
        <div className="pie">
          <PieChart width={250} height={250}>
            <Pie
              data={data}
              dataKey="survey"
              outerRadius={100}
              innerRadius={50}
              fill="#8884d8"
            >
              {data.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <p className="percent-e">{Math.round(completeSurvey)}%</p>
        </div>
      </div>
      <div className="d-flex justify-content-around">
        {data.map((entry, index) => (
          <div className="d-flex" key={entry.name}>
            <div
              className="dot1 me-3"
              style={{ backgroundColor: entry.color }}
            />
            <p>{entry.name}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default EmployeeChart
