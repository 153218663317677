/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { Table, Button, Spin } from 'antd'
import { useState, useEffect } from 'react'
import { MoreOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { Doughnut, defaults } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import more from '../../../assets/images/more.svg'
import '../style/report.scss'
import {
  getCompanyReportTableDataApi,
  getReportProjectNestedTableDataApi,
  filterReportCompantyApi,
} from '../../../api/API'
import { CompanyFilterBar } from '../companyFilterBar'

const columns = [
  // {
  // dataIndex: 'id',
  // render: (text: any) => <p className="d-none"> {text} </p>,
  // },
  {
    title: 'COMPANY NAME',
    dataIndex: 'company_name',
    render: (text: any, record: any) => (
      <Link to={{ pathname: `/company-detail/${record.id}` }}>{text}</Link>
    ),
  },
  {
    title: 'COMPANY ID',
    dataIndex: 'company_id',
  },
  {
    title: 'TOTAL PROJECT',
    dataIndex: 'total_project',
  },
  {
    title: 'USERS',
    dataIndex: 'total_user',
  },
  {
    title: 'FOCUS AREA',
    dataIndex: 'total_focus_area',
  },
  {
    title: 'RATERS',
    dataIndex: 'total_raters',
  },
  {
    title: 'CREATED ON',
    dataIndex: 'created_at',
  },
  // {
  //   title: 'RESPONSES',
  //   dataIndex: 'responses',
  //   render: (text: any) => <a href="/project-detail"> 0 from 9 </a>,
  // },
  // {
  //   title: 'SURVEY',
  //   dataIndex: 'servrey',
  //   render: (record: any) => {
  //     return (
  //       <div className="card1-chart-wrapper">
  //         <div className="label-wrapper">
  //           <span>80%</span>
  //         </div>
  //         <div className="chart-wrapper">
  //           <LableChart />
  //         </div>
  //       </div>
  //     )
  //   },
  // },
  {
    title: 'CONTACT PERSON',
    dataIndex: 'contact_person',
  },
  // {
  //   render: (text: any) => (
  //     <div>
  //       {/* <Button> Download Report </Button> */}
  //       <img src={more} alt="more" className="more_img ms-3" />
  //     </div>
  //   ),
  // },
]

export const CompanyTable: React.FC = (): JSX.Element => {
  const [tableData, setTableData] = useState<any>([])
  const [projectTableData, setProjectTableData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filterQuery, setFilterQuery] = useState<any>({})
  const [filterSearchBar, setFilterSearchBar] = useState('')
  const [filterStatus, setFilterStatus] = useState('')
  const [filterCompanyName, setFilterCompanyName] = useState('')
  const [loading, setLoading] = useState(false)

  const controller = new AbortController()

  const getCompanyReportTableData = (): void => {
    setLoading(true)
    const data = {
      page_no: currentPage,
    }
    getCompanyReportTableDataApi(data).then((res: any) => {
      setTableData(res.data.data.data)
      setFilterSearchBar('')
      setFilterStatus('')
      setFilterCompanyName('')
      setTotalPages(res.data.data.total_pages)
      setLoading(false)
      // console.log(res.data.data)
    })
  }

  useEffect(() => {
    getCompanyReportTableData()
  }, [currentPage])
  // call nested table data of project list api
  // pass company id to get data
  const getProjectListData = (companyId: any): void => {
    console.log(companyId)
    const data = { company_id: companyId }
    getReportProjectNestedTableDataApi(data).then((res: any) => {
      setProjectTableData(res.data.data)
      // console.log(res.data.data)
    })
  }

  const chartFn = (completed: any): any => {
    return {
      datasets: [
        {
          data: [completed || 0, completed ? 100 - completed : 100],
          backgroundColor: ['#008174', '#b2d9d5'],
          hoverOffset: 0,
        },
      ],
    }
  }

  // function that contain nested table
  // display project list data in nested table row
  const expandedRowRender = (): any => {
    const innercolumns = [
      {
        title: 'PROJECT NAME',
        dataIndex: 'project_name',
        render: (text: any, record: any) => (
          <Link to={{ pathname: `/project-detail/${record._id}` }}>{text}</Link>
        ),
      },
      {
        title: 'PROJECT ID',
        dataIndex: 'projectID',
      },
      {
        title: 'EMPLOYEES',
        dataIndex: 'employees',
      },
      {
        title: 'FOCUS AREA',
        dataIndex: 'focusarea',
      },
      {
        title: 'CONSTRUCTS',
        dataIndex: 'construct',
      },
      {
        title: 'RATER CATEGORIES',
        dataIndex: 'raterscategory',
      },
      {
        title: 'CREATED ON',
        dataIndex: 'created_at',
      },
      {
        title: 'RESPONSES',
        dataIndex: 'responses',
      },
      {
        title: 'SURVEY',
        dataIndex: 'surveyPersentage',
        render: (record: any) => {
          return <p className="mb-0">{Math.round(record) || 0}%</p>
        },
        // render: (record: any) => {
        //   return (
        //     <div className="card1-chart-wrapper">
        //       {Math.round(record) || 0}
        //       {/* <div className="label-wrapper">
        //         <span style={{ marginLeft: '5px' }}>
        //           {Math.round(record) || 0}%
        //         </span>
        //       </div>
        //       <div className="chart-wrapper">
        //         <Doughnut data={chartFn(record)} />
        //       </div> */}
        //     </div>
        //   )
        // },
      },
      {
        title: 'Contact Person',
        dataIndex: 'contact_person',
      },
      {
        render: (text: any, record: any) => (
          <Link to={`/reportPdf/${record._id}/project`}>
            <Button> Download Report</Button>
          </Link>
        ),
      },
    ]

    return (
      <Table
        columns={innercolumns}
        dataSource={projectTableData}
        pagination={false}
      />
    )
  }

  const filterCompany = (key: string, value: any): void => {
    // controller.abort()
    const existingQury = filterQuery
    if (key === 'name' && value.target.value !== '') {
      existingQury[key] = value.target.value
    }
    if (key === 'status' && value !== 'all') {
      existingQury[key] = value
      // existingQury[key] = value ? 'active' : 'deactivate'
    }
    if (key === 'companyId' && value !== 'all') {
      existingQury[key] = value
    }
    if (key === 'date_added' && value !== null) {
      existingQury[key] = value
    }
    if (key === 'date_added' && value === null) {
      getCompanyReportTableData()
      return
    }
    if (key === 'name' && value.target.value === '') {
      getCompanyReportTableData()
      return
    }
    if (key === 'mode' && value === 'all') {
      getCompanyReportTableData()
      return
    }
    if (key === 'companyId' && value === 'all') {
      getCompanyReportTableData()
      return
    }
    setFilterQuery(existingQury)
    filterReportCompantyApi(existingQury, controller)
      .then((res: any): any => {
        setTableData(res.data.data.data)
        controller.abort()
      })
      .catch((error: any) => {
        // if (error.response.status === 401) {
        //   console.log(error)
        // }
      })
  }
  return (
    <Spin spinning={loading} className="spinner-code">
      <CompanyFilterBar
        filterCompany={filterCompany}
        filterSearchBar={filterSearchBar}
        filterCompanyName={filterCompanyName}
        filterStatus={filterStatus}
        tableData={tableData}
        getCompanyReportTableData={getCompanyReportTableData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <Table
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        rowKey="id"
        className="components-table-demo-nested h-100 pt-3"
        expandable={{
          expandedRowRender,
          expandIconColumnIndex: 10,
          rowExpandable: (record) => record.id !== 'Not Expandable',
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <Button
              onClick={(e) => {
                onExpand(record, e)
              }}
            >
              Download Report
              <span className="ps-2">
                <UpOutlined />
              </span>
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                onExpand(record, e)
                getProjectListData(record.id)
              }}
            >
              Download Report
              <span className="ps-2">
                <DownOutlined />
              </span>
            </Button>
          )
        }
      />
    </Spin>
  )
}
