import React, { useEffect, useState } from 'react'
import { Tabs, notification } from 'antd'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { Details } from './tabs/details'
import { User } from './tabs/user'
import { FrameWork } from './tabs/framework'
import { Survey } from './tabs/survey'
import { DataAndAnalysis } from './tabs/dataAndAnalysis'
import { Distribution } from './tabs/distribution'
import { getProjectDetail } from '../../api/API'
import Header from './header'
import { readCookie } from '../../api/CookieScript'
import {
  COMPANY_ROLE,
  ROLE_KEYWORD,
  SUPER_ADMIN_ROLE,
} from '../../constants/index'

import 'antd/dist/antd.css'
import './style/project.scss'

const { TabPane } = Tabs

export const ProjectDetail: React.FC = (): JSX.Element => {
  // const location = useLocation()
  const params = useParams<any>()
  const history = useHistory()
  const [detail, setDetail] = useState({})

  const getProjectDetailFn = (id: any): any => {
    getProjectDetail(id)
      .then((res: any) => {
        // console.log(res)
        const { data } = res.data
        // console.log(res.data.data)
        console.log(data)
        setDetail(data)
      })
      .catch((err: any) => {
        history.push('/my-project')
      })
  }

  useEffect(() => {
    const { id } = params // project id
    getProjectDetailFn(id)
  }, [])

  return (
    <div className="min-85vh">
      <div className="project-container site-drawer-render-in-current-wrapper">
        <Header project={detail} getProjectDetailFn={getProjectDetailFn} />
        <hr />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Details" key="1">
            {detail && <Details detail={detail} projectId={params.id} />}
          </TabPane>
          <TabPane tab="Users" key="2">
            <User
              projectDetail={detail}
              getProjectDetailFn={getProjectDetailFn}
            />
          </TabPane>
          {/* these 3 tab panel display only to super admin and company user  */}
          {(readCookie(ROLE_KEYWORD) === SUPER_ADMIN_ROLE ||
            readCookie(ROLE_KEYWORD) === COMPANY_ROLE) && (
            <>
              <TabPane tab="Framework" key="3">
                {detail && <FrameWork projectDetail={detail} />}
              </TabPane>
              <TabPane tab="Survey" key="4">
                {detail && <Survey projectDetail={detail} />}
              </TabPane>
            </>
          )}
          <TabPane tab="Distribution" key="5">
            <Distribution />
          </TabPane>
          <TabPane tab="Data & Analysis" key="6">
            <DataAndAnalysis />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
